import React from 'react';

export default function FeaturesCards({ background, iconBackground, header, text, icon }) {
    return (
        <>
            <div
                style={{
                    background: background,
                }}
                className="w-full xl:w-[26.55188rem] h-[21.575rem] p-[3.12rem] rounded-[.625rem]"
            >
                <div className="flex flex-col gap-[2rem]">
                    <div
                        style={{
                            background: iconBackground,
                        }}
                        className="w-[5rem] h-[5rem] flex justify-center items-center rounded-full"
                    >
                        {icon}
                    </div>

                    <div className="flex flex-col gap-[.75rem]">
                        <span className="landing_features_card_header">{header}</span>
                        <span className="landing_features_card_txt">{text}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
