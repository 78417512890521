import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export default function BreadCrumb({ breadList, header, info, home }) {
	const { activeChama } = useSelector((state) => state.obj);

	const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        if (!activeChama?.custId && !home) {
            setShouldRedirect(true);
        }
    }, [activeChama]);

    if (shouldRedirect) {
        return <Navigate to="/dashboard" replace />;
    }
	

	return (
		<>
			<div className='w-full flex flex-col gap-[.75rem] bg-white'>
				<Breadcrumb separator='/' items={breadList} />
				<span className='heading_1'>
					{header}
				</span>
				<span className='paragraph_1'>{info}</span>
			</div>
		</>
	);
}
