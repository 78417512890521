import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../../layout/BreadCrumb';
import { useEffect, useState } from 'react';
import { Spin, Switch } from 'antd';
import CustomAllPurposeOtpModal from '../../components/CustomAllPurposeOtpModal';
import useModalToggle from '../../custom_hooks/useModalToggle';
import UpdateUserDetailsModal from './modals/UpdateUserDetailsModal';
import { applicationUrl, customToast, formatFilePath } from '../../utils';
import { save, saveFile } from '../../features/save/saveSlice';
import CardLoading from '../../components/CardLoading';
import { handleUpdateUser } from '../../features/auth/authSlice';
import UpdateUserPasswordModal from './modals/UpdateUserPasswordModal';

export default function ProfileSettings() {
    const dispatch = useDispatch();

    const verificationModal = useModalToggle();
    const updateModal = useModalToggle();
    const updatePasswordModal = useModalToggle();

    const { user } = useSelector((state) => state.auth);
    const { saving } = useSelector((state) => state.save);

    const [isOn, setisOn] = useState(user?.hasTwoFactorAuth ?? false);
    const [text, settext] = useState('');
    const [currentStatus, setcurrentStatus] = useState('');
    const [header, setheader] = useState('');
    const [otpObj, setotpObj] = useState({});
    const [updateObj, setupdateObj] = useState({});

    const status = {
        email: 'VERIFY_CURRENT_EMAIL',
        phone: 'VERIFY_CURRENT_PHONE',
        newPassword: 'VERIFY_PASSWORD',
        twoFactorAuth: 'TWO_FACTOR_AUTH',
        finalize: 'FINALIZE',
        finalizePassword: 'FINALIZE_PASSWORD',
        changePassword: 'CHANGE_PASSWORD',
        fileUpload: 'FILE_UPLOAD',
    };

    const onChange = async () => {
        await handleChangeTwoFactorAuth();
    };

    async function handleUploadProfile(file) {
        await setcurrentStatus(status.fileUpload);
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            return customToast({
                content: `File must be smaller than 20MBs`,
                bdColor: '',
                id: 72782762691,
            });
        }

        const res = await dispatch(saveFile(file));

        if (res?.payload?.success) {

            const saveObj = {
                usrLogo: res?.payload?.data?.result,
                url: applicationUrl().url,
                saveUrl: '/api/v2/users/update-user',
            };
    
            const response = await dispatch(save(saveObj));

            if (response?.payload?.success) {
                await dispatch(
                    handleUpdateUser({
                        usrLogo: res?.payload?.data?.result,
                    })
                );
            }else{
                await customToast({
                    content: res?.payload?.messages?.message ?? 'Could not upload file. Please try again later',
                    bdColor: 'error',
                    id: 736353333333,
                });
            }
            
        } else {
            await customToast({
                content: res?.payload?.messages?.message ?? 'Could not upload file. Please try again later',
                bdColor: 'error',
                id: 112233445566778899,
            });
        }

        await setcurrentStatus('');
    }

    async function handleUpdate(values) {
        await setupdateObj(values);

        let data = {
            ...otpObj,
            ...values,
        };

        await setotpObj(data);

        if (currentStatus === status.newPassword) {
            await settext('Enter OTP sent to your email and phone number');
            await verificationModal.handleOpen();
            await updatePasswordModal.handleCancel();
            await setcurrentStatus(status.finalizePassword);
        } else {
            await settext(`Enter OTP sent to ${values?.email ?? values?.phone}`);
            await verificationModal.handleOpen();
            await updateModal.handleCancel();
            await setcurrentStatus(status.finalize);
        }
    }

    async function handleChangeEmail() {
        await setcurrentStatus(status.email);

        const saveObj = {
            usrId: user?.jti,
            otpType: 'EMAIL',
            email: user?.email,
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/custom-otp',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await settext(`Enter OTP sent to ${user?.email}`);
            await setotpObj({
                otpType: 'EMAIL',
                email: user?.email,
            });
            await verificationModal.handleOpen();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'Could not verify email. Please try again later',
                bdColor: 'error',
                id: 88888999999977,
            });
        }
    }

    async function handleChangePhone() {
        await setcurrentStatus(status.phone);

        const saveObj = {
            usrId: user?.jti,
            otpType: 'PHONE',
            phone: user?.phone,
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/custom-otp',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await settext(`Enter OTP sent to ${user?.phone}`);
            await setotpObj({
                otpType: 'PHONE',
                phone: user?.phone,
            });
            await verificationModal.handleOpen();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'Could not verify email. Please try again later',
                bdColor: 'error',
                id: 113344556,
            });
        }
    }

    async function handleChangeTwoFactorAuth() {
        await setcurrentStatus(status.twoFactorAuth);

        const saveObj = {
            usrId: user?.jti,
            otpType: 'BOTH',
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/custom-otp',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await settext(`Enter OTP sent to your email and phone number`);
            await setotpObj({
                otpType: 'BOTH',
                phone: user?.phone,
                email: user?.email,
            });
            await verificationModal.handleOpen();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'Could not process your request. Please try again later',
                bdColor: 'error',
                id: 66677762,
            });
        }
    }

    async function handleChangePassword() {
        await setcurrentStatus(status.newPassword);
        await setheader('Current Password');
        await settext('Enter your current password');
        await setotpObj({
            otpType: 'BOTH',
        });
        await updatePasswordModal.handleOpen();
    }

    async function handleVerify(cancatenatedString) {
        let success;

        const saveObj = {
            otpType: 'VERIFY',
            secret: cancatenatedString,
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/custom-verify-update',
        };
        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            success = true;
            customToast({
                content: res?.payload?.messages?.message,
                bdColor: 'success',
                id: 999888111,
            });
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'Could not verify email. Please try again later',
                bdColor: 'error',
                id: 763782989,
            });
            success = false;
        }
        return success;
    }

    async function handleNext(cancatenatedString) {
        let success;
        switch (currentStatus) {
            case 'VERIFY_CURRENT_EMAIL':
                success = await handleVerify(cancatenatedString);

                if (success) {
                    await setheader('New Email');
                    await settext(`Enter OTP sent to ${otpObj?.email}`);
                    await updateModal.handleOpen();
                    await verificationModal.handleCancel();
                }
                break;

            case 'VERIFY_CURRENT_PHONE':
                success = await handleVerify(cancatenatedString);

                if (success) {
                    await setheader('New Phone number');
                    await settext(`Enter OTP sent to ${otpObj?.phone}`);
                    await updateModal.handleOpen();
                    await verificationModal.handleCancel();
                }
                break;

            case 'FINALIZE':
                success = await handleVerify(cancatenatedString);

                if (success) {
                    await handleFinalUpdate();
                }
                break;

            case 'FINALIZE_PASSWORD':
                success = await handleVerify(cancatenatedString);

                if (success) {
                    await handleChangePasswordToNew();
                }
                break;

            case 'TWO_FACTOR_AUTH':
                success = await handleVerify(cancatenatedString);

                if (success) {
                    await handleUpdateTwoFactorAuth();
                }
                break;

            default:
                break;
        }
    }

    async function handleFinalUpdate() {
        const saveObj = {
            ...updateObj,
            otpType: 'UPDATE',
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/custom-verify-update',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await dispatch(handleUpdateUser(updateObj));
            await handleClose();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'Could not verify email. Please try again later',
                bdColor: 'error',
                id: 8662,
            });
        }
    }

    async function handleUpdateTwoFactorAuth() {
        const saveObj = {
            usrHasTwoFactorAuth: !isOn,
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/update-user',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await setisOn(!isOn);
            await dispatch(
                handleUpdateUser({
                    hasTwoFactorAuth: !isOn,
                })
            );
            await handleClose();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'Could not verify email. Please try again later',
                bdColor: 'error',
                id: 8662,
            });
        }
    }

    async function handleChangePasswordToNew() {
        await setcurrentStatus(status.changePassword);
        await setheader('New Password');
        await settext('Enter your new password');
        await verificationModal.handleCancel();
        await updatePasswordModal.handleOpen();
    }

    function handleClose() {
        settext('');
        setcurrentStatus('');
        setheader('');
        setotpObj({});
        setupdateObj({});
        verificationModal.handleCancel();
        updateModal.handleCancel();
        updatePasswordModal.handleCancel();
    }

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    useEffect(() => {}, [currentStatus, user, updateObj, otpObj, text]);

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Home</span>
                </div>
            ),
            href: '/#/dashboard',
        },
        {
            title: 'Profile settings',
        },
    ];

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] pb-[5rem]">
                <div className="justify-between items-center white_card">
                    <BreadCrumb breadList={breadList} header={'Account settings'} info={'Manage your account details'} home={true} />
                </div>

                <div className="white_card !p-[2.5rem] flex-col">
                    <span className="heading_4">Profile</span>

                    <div className="w-full flex flex-col lg:flex-row lg:items-center gap-[.75rem] mt-[2.87rem]">
                        <img
                            loading="lazy"
                            src={
                                user?.usrLogo ? formatFilePath(user?.usrLogo) : `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.userName)}&background=B6BEC9&color=fff`
                            }
                            alt="userImg"
                            className="w-[9.5rem] h-[9.375rem] rounded-full"
                        />

                        <label className="upload_avatar" htmlFor="profileImage">
                            {saving && currentStatus === status.fileUpload ? (
                                <div className="flex items-center gap-[.75rem]">
                                    <Spin /> <span className="animate-pulse">Uploading file...</span>
                                </div>
                            ) : (
                                'Upload image'
                            )}
                        </label>
                        <input onChange={(e) => handleUploadProfile(e.target.files[0])} type="file" name="" id="profileImage" accept=".png, .jpg, .jpeg" hidden />
                    </div>

                    <div className="mt-[2.56rem] w-full grid grid-cols-1 lg:grid-cols-2 gap-[2.5rem]">
                        <div className="w-full flex flex-col gap-[.5rem]">
                            <label className="label_2">Full name</label>
                            <div className="border border-[#C2C2C2] rounded-[.5rem] flex items-center h-[2.75rem] p-[.5rem] bg-[#F9F9F9]">{user?.userName}</div>
                        </div>

                        <div className="w-full flex flex-col gap-[.5rem]">
                            <label className="label_2">ID Number</label>
                            <div className="border border-[#C2C2C2] rounded-[.5rem] flex items-center h-[2.75rem] p-[.5rem] bg-[#F9F9F9]">{user?.sub}</div>
                        </div>
                    </div>
                </div>

                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[1.25rem]">
                    {saving && currentStatus === status.email ? (
                        <CardLoading />
                    ) : (
                        <div className="white_card !p-[2.5rem] flex-col">
                            <div className="w-full flex justify-between items-center">
                                <span className="heading_4">Email</span>
                                <button onClick={handleChangeEmail} className="label_2 !text-[#4166C0]" type="button">
                                    Change
                                </button>
                            </div>

                            <div className="w-full flex flex-col gap-[.5rem] mt-[2.56rem]">
                                <label className="label_2">Email address</label>
                                <div className="border border-[#C2C2C2] rounded-[.5rem] flex items-center h-[2.75rem] p-[.5rem] bg-[#F9F9F9]">{user?.email}</div>
                            </div>
                        </div>
                    )}

                    {saving && currentStatus === status.phone ? (
                        <CardLoading />
                    ) : (
                        <div className="white_card !p-[2.5rem] flex-col">
                            <div className="w-full flex justify-between items-center">
                                <span className="heading_4">Phone number</span>
                                <button onClick={handleChangePhone} className="label_2 !text-[#4166C0]" type="button">
                                    Change
                                </button>
                            </div>

                            <div className="w-full flex flex-col gap-[.5rem] mt-[2.56rem]">
                                <label className="label_2">Phone number</label>
                                <div className="border border-[#C2C2C2] rounded-[.5rem] flex items-center h-[2.75rem] p-[.5rem] bg-[#F9F9F9]">{user?.phone}</div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[1.25rem]">
                    <div className="white_card !p-[2.5rem] flex-col">
                        <div className="w-full flex justify-between items-center">
                            <span className="heading_4">Password</span>
                            <button onClick={handleChangePassword} className="label_2 !text-[#4166C0]" type="button">
                                Change
                            </button>
                        </div>

                        <div className="w-full flex flex-col gap-[.5rem] mt-[2.56rem]">
                            <label className="label_2">Current password</label>
                            <div className="border border-[#C2C2C2] rounded-[.5rem] flex items-center h-[2.75rem] p-[.5rem] bg-[#F9F9F9]">*********************</div>
                        </div>
                    </div>

                    {saving && currentStatus === status.twoFactorAuth ? (
                        <CardLoading />
                    ) : (
                        <div className="white_card !p-[2.5rem] flex-col">
                            <div className="w-full flex justify-between items-center">
                                <span className="heading_4">Two factor authentication</span>
                            </div>

                            <div className="w-full flex items-center gap-[1.25rem] mt-[2.5rem]">
                                <div className="flex items-center gap-[.25rem]">
                                    <Switch checked={isOn} onChange={onChange} />
                                    <span>{isOn ? 'On' : 'Off'}</span>
                                </div>
                                <div className="flex flex-col gap-[.25rem]">
                                    <span className="label_2 !text-[#6B7280]">One Time Password (OTP)</span>
                                    <span className="paragraph_1">OTP sent to your email or phone </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <CustomAllPurposeOtpModal open={verificationModal.open} handleCancel={handleClose} text={text} handleNext={handleNext} otpObj={otpObj} />
            <UpdateUserDetailsModal open={updateModal.open} header={header} handleCancel={handleClose} otpObj={otpObj} handleUpdate={handleUpdate} />
            <UpdateUserPasswordModal
                open={updatePasswordModal.open}
                header={header}
                handleCancel={handleClose}
                handleUpdate={handleUpdate}
                currentStatus={currentStatus}
                text={text}
            />
        </>
    );
}
