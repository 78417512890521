import { Tabs } from 'antd';
import BreadCrumb from '../../layout/BreadCrumb';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { capitalize } from '../../utils';
import PageActions from '../../components/PageActions';
import Search from '../../components/Search';
import StatementFilter from '../statements/StatementFilter';
import PendingWithdrawalsApprovalTable from './tables/PendingWithdrawalsApprovalTable';
import useModalToggle from '../../custom_hooks/useModalToggle';
import SettlementModal from './modals/SettlementModal';
import AllSettlementsTab from './tabs/AllSettlementsTab';
import BankSettlementsTab from './tabs/BankSettlementsTab';
import MpesaSettlementsTab from './tabs/MpesaSettlementsTab';

export default function Settlement() {
    const { open, handleCancel, handleOpen } = useModalToggle();

    const { activeTab, activeChama } = useSelector((state) => state.obj);

    const [withdrawalType, setwithdrawalType] = useState('');

    function handleWithdrawal(value) {
        setwithdrawalType(value);
        handleOpen();
    }

    function handleClose() {
        setwithdrawalType('');
        handleCancel();
    }

    useEffect(() => {}, [activeTab]);

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const items = [
        {
            key: '1',
            label: 'All settlements',
            children: <AllSettlementsTab />,
        },
        {
            key: '2',
            label: 'Bank',
            children: <BankSettlementsTab />,
        },
        {
            key: '3',
            label: 'M-PESA',
            children: <MpesaSettlementsTab />,
        },
    ];

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Home</span>
                </div>
            ),
            href: '/#/dashboard',
        },
        {
            title: capitalize(activeChama?.custName),
        },
    ];

    const pageActions = [
        {
            key: '1',
            label: (
                <div onClick={() => handleWithdrawal('Bank')} className="pointer">
                    Settle funds to Bank
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div onClick={() => handleWithdrawal('M-Pesa')} className="pointer">
                    Settle funds to M-PESA
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] pb-[5rem]">
                <div className="justify-between items-center white_card">
                    <BreadCrumb breadList={breadList} header={'Settlement'} info={'Here’s an overview of group settlements'} />
                    <PageActions pageActions={pageActions} />
                </div>

                <div className="white_card flex-col min-h-[35rem]">
                    <Tabs className="underlined_tab pt-[1.87rem]" items={items} />
                </div>

                <div className="white_card flex-col">
                    <span className="heading_4 mt-[1rem]">Pending approval</span>
                    <div className="w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-[1rem] w-full">
                                <div className="w-[13.625rem] hidden lg:flex">
                                    <Search text={'Search'} />
                                </div>

                                <div className="w-[12.5rem] hidden lg:flex">
                                    <StatementFilter />
                                </div>
                            </div>
                        </div>

                        <PendingWithdrawalsApprovalTable />
                    </div>
                </div>
            </div>
            <SettlementModal open={open} handleCancel={handleClose} withdrawalType={withdrawalType} />
        </>
    );
}
