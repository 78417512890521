import { useEffect, useState } from 'react';
import { Form, Input, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, customToast, formatBankPhoneNumber } from '../../../../utils';
import { save } from '../../../../features/save/saveSlice';
import { handleNewChamaData } from '../../../../features/obj/objSlice';
import RemoveSettlementAccountModal from '../../modals/RemoveSettlementAccountModal';
import { fetchBanks, veriBankAccount } from '../../../../features/options/optionSLice';
import PhoneInput from 'react-phone-input-2';
import FileUploader from '../../../../components/FileUploader';

export default function SettleMentAccountBankSubStep({ form, handleBack, handleFetch, handleReview }) {
    const dispatch = useDispatch();

    const { settlementAccounts } = useSelector((state) => state.fetch);
    const { newChamaData } = useSelector((state) => state.obj);
    const { banks } = useSelector((state) => state.option);
    const { saving } = useSelector((state) => state.save);
    const { optLoading } = useSelector((state) => state.option);

    const [add, setadd] = useState(false);
    const [staProof, setstaProof] = useState('');
    const [deleteObj, setdeleteObj] = useState({});
    const [open, setopen] = useState(false);
    const [verifyBank, setverifyBank] = useState(false);
    const [verifyObj, setverifyObj] = useState({});

    function handleOpen(value) {
        setopen(true);
        setdeleteObj(value);
    }

    function handleCancel() {
        setopen(false);
        setdeleteObj({});
        setverifyBank(false);
        setverifyObj({});
    }

    const handleSuccess = (response) => {
        setstaProof(response?.data?.result);
    };

    async function handleFetchBanks() {
        await dispatch(fetchBanks());
    }

    async function handleVerifyBank() {
        try {
            await form.validateFields(['staBankCode', 'staAccountNumber', 'staPhoneNumber']);
            let data = form.getFieldsValue();
            let bankObj = banks?.find((item) => item?.bnkNo === data?.staBankCode);
            data.staBankName = bankObj?.bnkName;
            data.staProof = staProof;
            data.staCustId = newChamaData?.custId;
            data.staPhoneNumber = formatBankPhoneNumber(data?.staPhoneNumber);
            data.staType = 'BANK';
            data.url = applicationUrl().url;
            data.saveUrl = '/api/v1/settlement';

            const otherObj = {
                accountNo: data.staAccountNumber,
                bankCode: '00' + data.staBankCode,
            };

            const res = await dispatch(veriBankAccount(otherObj));

            if (res?.payload?.status === 'SUCCESS') {
                setverifyObj({
                    ...data,
                    staName: res?.payload?.benname,
                });

                await setadd(false)
                await setverifyBank(true)
            } else {
                customToast({
                    content: 'Could not verify your bank account.',
                    bdColor: 'error',
                    id: 927722222
                });
            }
        } catch {}
    }

    async function handleSave() {
        try {
            const res = await dispatch(save(verifyObj));

            if (res?.payload?.success) {
                await setstaProof('');
                await dispatch(
                    handleNewChamaData({
                        staBankCode: null,
                        staAccountNumber: null,
                        staName: null,
                        staPhoneNumber: null,
                    })
                );
                await setverifyBank(false);
                await setverifyObj({});
                await setadd(false);
                await handleFetch();
            } else {
                customToast({
                    content: res?.payload?.messages?.message ?? 'An error occured',
                    bdColor: 'error',
                });
            }
        } catch {}
    }

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
        handleFetchBanks();
    }, []);

    const colors = ['#F9FCFF', '#F9FFFA', '#FDF9FF'];

    return (
        <>
            <div className="flex flex-col w-full">
                {settlementAccounts?.length && !add && !verifyBank ? (
                    <>
                        <div className="flex flex-col justify-center h-auto items-center w-full gap-[.38rem]">
                            {settlementAccounts
                                ?.filter((x) => x?.staType === 'BANK')
                                ?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                background: colors[index],
                                            }}
                                            className="flex gap-[1.5rem] justify-between items-center w-full lg:w-[35rem] rounded-[0.5rem] min-h-[4.625rem] h-auto px-[2rem] py-[.5rem]"
                                        >
                                            <div className="flex flex-col gap-[.75rem] items-start">
                                                <span className="role_header">
                                                    {item?.staAccountNumber} | {item?.staBankName} BANK
                                                </span>
                                                <span className="role_header">{item?.staName}</span>
                                                {item?.staProof ? <span className="role_header underline">{item?.staProof?.split('_').pop()}</span> : null}
                                            </div>
                                            <button type="button" onClick={() => handleOpen(item)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M20.25 4.5H16.5V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5H9.75C9.15326 1.5 8.58097 1.73705 8.15901 2.15901C7.73705 2.58097 7.5 3.15326 7.5 3.75V4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM9 3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5H9V3.75ZM18 19.5H6V6H18V19.5ZM10.5 9.75V15.75C10.5 15.9489 10.421 16.1397 10.2803 16.2803C10.1397 16.421 9.94891 16.5 9.75 16.5C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75V9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9C9.94891 9 10.1397 9.07902 10.2803 9.21967C10.421 9.36032 10.5 9.55109 10.5 9.75ZM15 9.75V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5C14.0511 16.5 13.8603 16.421 13.7197 16.2803C13.579 16.1397 13.5 15.9489 13.5 15.75V9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75Z"
                                                        fill="#343330"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    );
                                })}

                            <button type="button" onClick={() => setadd(true)} className="mt-[1.63rem] w-full lg:w-[35rem] flex items-center gap-[.5rem]">
                                <div className="black-round-btn">
                                    <svg className="z-[100]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11.9998 4.7998L11.9998 19.1998M19.1998 11.9998L4.7998 11.9998" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                </div>

                                <span className="role_btn_text">Add account</span>
                            </button>
                        </div>

                        <div className="py-[2.31rem] mt-[5rem] w-full flex justify-center items-center">
                            <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                <button type="button" onClick={handleReview} className="cstm-btn-2">
                                    Next
                                </button>

                                <button onClick={() => handleBack()} type="button" className="cstm-btn">
                                    Previous
                                </button>
                            </div>
                        </div>
                    </>
                ) : add ? (
                    <>
                        <Form.Item
                            className="mt-[3rem]"
                            label="Choose bank"
                            name="staBankCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required',
                                },
                            ]}
                        >
                            <Select
                                suffixIcon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                        <path
                                            d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                                            fill="#212121"
                                        />
                                    </svg>
                                }
                                options={banks?.map((item) => {
                                    return {
                                        label: item?.bnkName,
                                        value: item?.bnkNo,
                                    };
                                })}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Phone number"
                            name="staPhoneNumber"
                            extra="e.g 254 723456789"
                            validateFirst
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required',
                                },
                                {
                                    validator: (_, value) => {
                                        if (value && value.length === 12) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Invalid format. Use the correct format'));
                                    },
                                },
                            ]}
                        >
                            <PhoneInput enableSearch country="ke" countryCodeEditable={false} className="input" />
                        </Form.Item>

                        {/* <Form.Item
                            label="Account name"
                            name="staName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required',
                                },
                            ]}
                        >
                            <Input className="input" />
                        </Form.Item> */}

                        <Form.Item
                            label="Account number"
                            name="staAccountNumber"
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required',
                                },
                            ]}
                        >
                            <Input className="input" />
                        </Form.Item>

                        <Form.Item
                            label="Upload recent statement (.pdf)"
                            rules={[
                                {
                                    required: false,
                                    message: 'Field is required',
                                },
                            ]}
                        >
                            <FileUploader fileName={staProof?.split('_').pop()} name="staProof" onSuccess={handleSuccess} />
                        </Form.Item>

                        <div className="py-[2.44rem] w-full flex justify-center items-center">
                            <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                <button disabled={optLoading} onClick={handleVerifyBank} type="button" className="cstm-btn-2">
                                    {optLoading ? <Spin /> : 'Next'}
                                </button>

                                <button
                                    disabled={optLoading}
                                    onClick={() => {
                                        setadd(false);
                                        handleNewChamaData({
                                            staBankCode: null,
                                            staAccountNumber: null,
                                            staPhoneNumber: null,
                                        });
                                    }}
                                    type="button"
                                    className="cstm-btn"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                ) : verifyBank ? (
                    <>
                        <div className="flex flex-col justify-center items-center mt-[3.38rem] gap-[1.32rem]">
                            <span className="label_1">Add the following account?</span>
                            <div
                                style={{
                                    background: '#fff',
                                }}
                                className="flex gap-[1.5rem] justify-center items-center border border-[#E2E9EE] w-full lg:w-[35rem] rounded-[0.5rem] min-h-[4.875rem] h-auto px-[5rem] py-[1.25rem]"
                            >
                                <div className="flex flex-col gap-[.75rem] items-center">
                                    <span className="paragraph_2">
                                        {verifyObj?.staAccountNumber} | {verifyObj?.staBankName}
                                    </span>
                                    <span className="paragraph_2">{verifyObj?.staName}</span>
                                </div>
                            </div>
                        </div>

                        <div className="py-[2.44rem] w-full flex justify-center items-center">
                            <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                <button disabled={saving} onClick={handleSave} type="button" className="cstm-btn-2">
                                    {saving ? <Spin /> : 'Continue'}
                                </button>

                                <button
                                    disabled={saving}
                                    onClick={() => {
                                        setadd(true);
                                        setverifyBank(false);
                                        setverifyObj({});
                                    }}
                                    type="button"
                                    className="cstm-btn"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-col w-full mt-[4rem] justify-center items-center">
                            <span className="settlement_empty_text">Bank accounts you add will be displayed here </span>

                            <div className="w-full flex justify-center items-center">
                                <button onClick={() => setadd(true)} className="mt-[2.5rem] flex items-center gap-[.5rem]">
                                    <div className="black-round-btn">
                                        <svg className="z-[100]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M11.9998 4.7998L11.9998 19.1998M19.1998 11.9998L4.7998 11.9998" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                    </div>

                                    <span className="role_btn_text">Add account</span>
                                </button>
                            </div>

                            <div className="pb-[2.37rem] mt-[5rem] w-full flex justify-center items-center">
                                <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                    <button type="button" onClick={handleReview} className="cstm-btn-2">
                                        Skip
                                    </button>

                                    <button onClick={() => handleBack()} type="button" className="cstm-btn">
                                        Previous
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <RemoveSettlementAccountModal handleFetch={handleFetch} deleteObj={deleteObj} open={open} handleCancel={handleCancel} />
        </>
    );
}
