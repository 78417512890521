import { Form } from 'antd';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { customToast } from '../../utils';
import NationalIdStep from './forgot_pass_steps/NationalIdStep';
import ResetPasswordStep from './forgot_pass_steps/ResetPasswordStep';
import { resetPassword } from '../../features/auth/authSlice';

export default function ForgotPassword() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { isActive } = useSelector((state) => state.auth);

	const formRef = useRef(null);

	const [current, setcurrent] = useState(0);
	const [usrId, setusrId] = useState('');

	function handleGetUsrId(id) {
		setusrId(id);
	}

	const onFinish = async (data) => {
		data.usrId = usrId;
		const res = await dispatch(resetPassword(data));

		if (res?.payload?.success) {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'success',
				id: 66535535
			});
			await form.resetFields();
			await navigate('/login');
		} else {
			await customToast({
				content: res?.payload?.message,
				bdColor: 'error',
				id: 898262224
			});
		}
	};

	function handleNext() {
		setcurrent(current + 1);
	}

	const steps = [
		{
			key: 0,
			title: 'Personal Details',
			content: (
				<NationalIdStep
					usrId={usrId}
					handleGetUsrId={handleGetUsrId}
					handleNext={handleNext}
					form={form}
				/>
			),
		},

		{
			key: 1,
			title: 'Password Setup',
			content: <ResetPasswordStep />,
		},
	];

    if (isActive) {
        return <Navigate to="/dashboard" replace />;
    }

	return (
		<>
			<div className='flex w-full h-full'>
				<Form
					layout='vertical'
					ref={formRef}
					name='registration'
					onFinish={onFinish}
					scrollToFirstError
					form={form}
					className='w-full'>
					<div className='w-full'>{steps[current].content}</div>
				</Form>
			</div>
		</>
	);
}
