import { Table } from 'antd';
import { applicationUrl, capitalize, formatMoney, getRandomColor, formatFilePath } from '../../../utils';
import moment from 'moment';
import useFetch from '../../../custom_hooks/useFetch';
import TableLoading from '../../../components/TableLoading';
import { debitNoteTypeColor } from '../../../data';

export default function CollectionsStatementTable() {
    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/contr/fetchAll`);

    if (isLoading) return <TableLoading />;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'contDate',
            render: (item) => <span>{moment(item).format('Do MMMM YYYY')}</span>,
        },
        {
            title: 'Member',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img
                            src={item?.gpmProfilePhoto
                                ? formatFilePath(item?.gpmProfilePhoto)
                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(item?.gpmName)}&background=${getRandomColor()}&color=fff`}
                            className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                            alt="avatar"
                        />
                        <span>{item?.gpmName}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Activity',
            dataIndex: 'contType',
            render: (item) => {
                const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6';
                return (
                    <div
                        style={{
                            background: roleColor,
                        }}
                        className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                            <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                        </svg>
                        <span>{capitalize(item)}</span>
                    </div>
                );
            },
        },
		{
            title: 'Amount (KES)',
            dataIndex: 'contAmount',
            render: (item) => <span>{formatMoney(Math.abs(item), 'KES')}</span>,
        },
        {
            title: 'Msidn',
            dataIndex: 'contPaymentMsisdn',
        },
        {
            title: 'Ref no.',
            dataIndex: 'contReferenceNumber',
        },
        
    ];

    return (
        <>
            <Table
                rowKey="contId"
                className="mt-[1.5rem] !w-full"
                pagination={false}
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 1200,
                }}
            />
        </>
    );
}
