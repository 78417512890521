import { useState } from 'react';
import { Link } from 'react-router-dom';
import image10 from '../../../assets/svg/ndovuLogoNew.svg';

export default function NavbarComponent({ handleNavigate }) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <div
                style={{
                    flex: '1 0 0',
                    alignSelf: 'stretch',
                    borderRadius: '31.25rem',
                    background: 'rgba(255, 255, 255, 0.10)',
                    boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.05)',
                }}
                className="pl-[1.62rem] pt-[.75rem] pb-[.69rem] pr-[.87rem] w-full flex items-center justify-between"
            >
                <Link to="/home" className="flex items-center gap-[.5rem]">
                    <img src={image10} className="logo !w-fit h-full" alt="logo" />
                    <span className="landing_logo_txt hidden lg:flex">Chamify Africa.</span>
                </Link>

                <div className="hidden lg:flex items-center gap-[2.19rem]">
                    <button onClick={() => handleNavigate('home')} type="button" className="landing_menu_item">
                        Home
                    </button>
                    <button onClick={() => handleNavigate('features')} type="button" className="landing_menu_item">
                        Features
                    </button>
                    <button onClick={() => handleNavigate('about')} type="button" className="landing_menu_item">
                        About
                    </button>
                    <button onClick={() => handleNavigate('help')} type="button" className="landing_menu_item">
                        Get help
                    </button>
                </div>

                <div className="hidden lg:flex items-center gap-[2.19rem]">
                    <Link to="/login" type="button" className="landing_menu_item_login">
                        Login
                    </Link>
                    <Link to="/registration" type="button" className="register_btn">
                        <span className="landing_menu_item_login !text-[#141711]">Get started now</span>
                        <div className="w-[2.5rem] h-[2.5rem] bg-[#141711] flex justify-center items-center rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                                <g clipPath="url(#clip0_1775_738)">
                                    <path d="M1.25 7H15M15 7L9.5 1.5M15 7L9.5 12.5" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1775_738">
                                        <rect width="16" height="13" fill="white" transform="translate(0 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </Link>
                </div>

                <button
                    className="lg:hidden flex items-center justify-center w-[2.5rem] h-[2.5rem] bg-[#141711] rounded-full"
                    onClick={toggleMobileMenu}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="white">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>

            {isMobileMenuOpen && (
                <div className="lg:hidden flex flex-col bg-white shadow-md rounded-md p-4 absolute top-[6rem] right-[1rem] w-[90%] z-10">
                    <button
                        onClick={() => {
                            toggleMobileMenu();
                            handleNavigate('home');
                        }}
                        className="landing_menu_item py-2"
                    >
                        Home
                    </button>
                    <button
                        onClick={() => {
                            toggleMobileMenu();
                            handleNavigate('features');
                        }}
                        className="landing_menu_item py-2"
                    >
                        Features
                    </button>
                    <button
                        onClick={() => {
                            toggleMobileMenu();
                            handleNavigate('about');
                        }}
                        className="landing_menu_item py-2"
                    >
                        About
                    </button>
                    <button
                        onClick={() => {
                            toggleMobileMenu();
                            handleNavigate('help');
                        }}
                        className="landing_menu_item py-2"
                    >
                        Get help
                    </button>
                    <Link to="/login" className="landing_menu_item_login py-2">
                        Login
                    </Link>
                    <Link to="/registration" className="register_btn py-2 mt-2">
                        <span className="landing_menu_item_login !text-[#141711]">Get started now</span>
                    </Link>
                </div>
            )}
        </>
    );
}
