import image10 from '../assets/svg/ndovuLogoNew.svg';

export default function AuthHeader() {
	return (
		<>
			<div className='w-full bg-white flex items-center justify-between px-[2.12rem] py-[1rem]'>
				<div className='flex items-center w-full gap-[.25rem]'>
					<img src={image10} className='logo !w-fit h-full' alt='image2' />
					<span className='heading_4'>Chamify Africa.</span>
				</div>

				{/* <div className='flex items-center justify-end w-full gap-[1rem]'>
					<div className='w-fit lg:w-[8rem]'>
						<Link className='cstm-btn' to='/login'>
							Login
						</Link>
					</div>
					<div className='w-fit lg:w-[8rem]'>
						<Link className='cstm-btn-2' to='/registration'>
							Sign up
						</Link>
					</div>
				</div> */}
			</div>
		</>
	);
}

