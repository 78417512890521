import React, { useState } from 'react';
import useToggle from '../../../custom_hooks/useToggle';

const upArrow = (
    <div className="relative">
        <div
            style={{
                width: '1rem',
                height: '0.125rem',
                background: '#141711',
                position: 'absolute',
                right: '.5rem'
            }}
        ></div>
    </div>
);

const downArrow = (
    <div className="relative">
        <div
            style={{
                width: '1rem',
                height: '0.125rem',
                background: '#141711',
                transform: 'rotate(90deg)',
                position: 'absolute',
                right: '.5rem'
            }}
        ></div>
        <div
            style={{
                width: '1rem',
                height: '0.125rem',
                background: '#141711',
                position: 'absolute',
                right: '.5rem'
            }}
        ></div>
    </div>
);

const faqsItems = [
    {
        id: 1,
        header: 'What is Chamify?',
        text: `Chamify is a user-friendly platform that helps individuals and groups save, invest, and access loans; all in one place. We make it easier to reach your financial goals, whether you're saving alone or with others.`,
    },
    {
        id: 2,
        header: 'Can I integrate with M-PESA?',
        text: '',
    },
    {
        id: 3,
        header: 'How does group saving work?',
        text: '',
    },
    {
        id: 4,
        header: 'How does investing work in the app?',
        text: '',
    },
    {
        id: 5,
        header: 'Are there any fees?',
        text: '',
    },
];

export default function FAQsComponent() {
    const { show, toggle } = useToggle(true);

    const [activeId, setactiveId] = useState(1);

    function handleActiveId(val) {
        setactiveId(val);
        if (show) {
            return;
        }
        toggle();
    }

    return (
        <>
            <div className="w-full flex flex-col items-center justify-center">
                <span className="lanidng_faq_header">Frequently asked questions</span>

                <div className="mt-[3.12rem] w-full">
                    {faqsItems.map((item) => {
                        return (
                            <div className="w-full" key={item.id}>
                                <button onClick={() => handleActiveId(1)} className="w-full flex justify-between items-center mb-[1.25rem]">
                                    <span className="lanidng_faq_faq_header w-[90%] text-left">{item.header}</span>
                                    {show && activeId === item.id ? upArrow : downArrow}
                                </button>
                                {show && activeId === item.id ? <span className="lanidng_faq_faq_txt">{item.text}</span> : null}
                                <div className="w-full h-[.0625rem] bg-[#D9DAD7] mt-[1.88rem] mb-[2.06rem]"></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
