import { Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { customToast } from '../../../utils';
import RegisterOTPModal from '../modals/RegisterOTPModal';
import image10 from '../../../assets/svg/ndovuLogoNew.svg';
import loginImg from '../../../assets/svg/login.svg';
import { Link } from 'react-router-dom';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import { initiateForgotPassword } from '../../../features/auth/authSlice';

export default function NationalIdStep({
	handleNext,
	usrId,
	form,
	handleGetUsrId,
}) {
	const { open, handleCancel, handleOpen } = useModalToggle();
	const dispatch = useDispatch();

	const { authLoading } = useSelector((state) => state.auth);

	async function handleSubmit() {
		try {
			await form.validateFields(['nationalId']);
			let data = form.getFieldsValue();

			const res = await dispatch(initiateForgotPassword(data.nationalId));

			if (res?.payload?.success) {
				await handleGetUsrId(res?.payload?.data?.result?.usrId);
				await handleOpen();
			} else {
				customToast({
					content: res?.payload?.messages?.message ?? 'Internal server error',
					bdColor: 'error',
					id: 8635728293
				});
			}
		} catch {}
	}

	return (
		<>
			<div className='flex w-full h-[100vh]'>
				<div className='w-full h-full flex justify-center items-center p-3 lg:p-[8.87rem] bg-white'>
					<div className='flex flex-col w-full'>
						<div className='flex items-center w-full gap-[.25rem]'>
							<img src={image10} className='logo !w-fit h-full' alt='image2' />
							<span className='heading_4'>Chamify Africa.</span>
						</div>

						<Link to='/login' className='mt-[2.92rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'>
								<path
									d='M16.0002 26.1667L5.8335 16L16.0002 5.83334L17.4335 7.26668L9.66683 15H26.1668V17H9.66683L17.4335 24.7333L16.0002 26.1667Z'
									fill='#212121'
								/>
							</svg>
						</Link>

						<span className='heading_1 my-[1.5rem]'>Forgot your password?</span>
						<span className='paragraph_1 mb-[3.5rem]'>
							Enter your details below to reset your password
						</span>

						<Form.Item
							className='table-form-item'
							name='nationalId'
							label='National ID'
							rules={[
								{
									required: true,
									message: 'National ID is required',
								},
							]}>
							<Input
								className='input !w-[29.9375rem]'
								onKeyDown={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
							/>
						</Form.Item>

						<div className='flex justify-center w-[10.625rem] mt-[2.06rem]'>
							<button
								onClick={handleSubmit}
								className='cstm-btn-2'
								type='button'
								disabled={authLoading ? true : false}>
								{authLoading ? <Spin /> : 'Next'}
							</button>
						</div>

						<div className='w-full flex mt-[6.81rem]'>
							<span className='label_2'>Don’t have an account? </span>
							<Link
								className='label_2 !text-blu ml-[.1rem] underline'
								to='/registration'>
								Register
							</Link>
						</div>
					</div>
				</div>

				<div className='hidden lg:flex w-full h-[100vh]'>
					<img
						src={loginImg}
						className='w-full h-full object-cover'
						alt='login'
					/>
				</div>
			</div>
			<RegisterOTPModal
				usrId={usrId}
				isForgot={true}
				handleNext={handleNext}
				open={open}
				handleCancel={handleCancel}
			/>
		</>
	);
}
