import { useDispatch } from 'react-redux';
import { handleNewChamaData, setActiveChama } from '../../../features/obj/objSlice';
import { useNavigate } from 'react-router-dom';
import { fetchChamaToken } from '../../../features/auth/authSlice';
import moment from 'moment';
import { handleOpenKeys, handleSelectedKeys } from '../../../features/setup/setupSlice';
import { customToast, formatFilePath, getRandomColor } from '../../../utils';

export default function ChamaListCard({ item, handleLoading }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function handleActiveChama() {
        if (item?.custStatus === 'DRAFT') {
            await dispatch(handleNewChamaData(item));
            await navigate('/new/chama-group');
            return;
        }

        const res = await dispatch(fetchChamaToken(item?.custId));
        if (res?.payload?.success) {
            await handleLoading(true);
            await dispatch(handleOpenKeys(['4']));
            await dispatch(handleSelectedKeys(['4']));
            await dispatch(setActiveChama(item));
            await handleLoading(false);
            await navigate('/activity/overview');
        } else {
            customToast({
                content: 'We cannot log to your chama at the moment. Please try again later',
                bdColor: 'error',
                id: 88766663300,
            });
        }
    }

    return (
        <>
            <div
                onClick={handleActiveChama}
                style={{
                    border: '1px solid var(--Tile-stroke, #E2E9EE)',
                }}
                className="w-[25.0625rem] flex flex-col h-full px-[.625rem] py-[2.06rem] rounded-[1.5rem] min-h-[14rem] cursor-pointer"
            >
                <div className="flex justify-between items-start w-full">
                    <img
                        src={
                            item?.custProfileImage
                                ? formatFilePath(item?.custProfileImage)
                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(item?.custName)}&background=${getRandomColor()}&color=fff`
                        }
                        className="w-[5.25rem] h-[5.25rem] rounded-full object-cover"
                        alt="Chama icon"
                    />

                    <button className="black-round-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path
                                d="M4.16602 10.5001H15.8327M15.8327 10.5001L9.99935 4.66675M15.8327 10.5001L9.99935 16.3334"
                                stroke="white"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>

                <span className="heading_4 mt-[1.88rem]">
                    {item?.custName} | {item?.custMemberCount} {item?.custMemberCount > 1 ? 'members' : 'member'}
                </span>
                <span className="chama_card_date mt-[.75rem]">
                    {item?.custStatus === 'DRAFT' ? 'Incomplete chama' : `Joined ${moment(item?.joinedDate).format('Do MMM YYYY')}`}
                </span>
            </div>
        </>
    );
}
