import { message } from 'antd';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;
let isSessionExpired = false;
let isTokenExpired = false;

export const handleLogout = async () => {
    if (!isSessionExpired) {
        isSessionExpired = true;
        await message.info('Session has expired. Log in to continue');
        await localStorage.clear();
        window.location = './login';
        await window.location.reload();
    }
};

export const handleRefreshToken = async () => {
    if (!isTokenExpired) {
        isTokenExpired = await true;
        await fetch(`${url}/auth/refresh-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.success) {
                    axiosInstance.defaults.headers.common = {
                        Authorization: `Bearer ${data?.accessToken}`,
                    };
                    localStorage.setItem('token', data?.accessToken);
                } else {
                    handleLogout();
                }
            });

        isTokenExpired = await false;
    }
};

const axiosInstance = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const statusCode = error.response ? error.response.status : null;

        if ([401, 403, 400].includes(statusCode)) {
            handleRefreshToken();
        } else if (statusCode >= 500) {
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
