import { useEffect } from 'react';
import BreadCrumb from '../../layout/BreadCrumb';
import ActivitySummaryCard from './cards/ActivitySummaryCard';
import InAppNotificationBar from '../../components/InAppNotificationBar';
import Search from '../../components/Search';
import StatementFilter from '../statements/StatementFilter';
import ActivityRecentTransactionTable from './tables/ActivityRecentTransactionTable';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, capitalize, formatMoney, getFistName } from '../../utils';
import useFetch from '../../custom_hooks/useFetch';
import CardLoading from '../../components/CardLoading';
import PayNowModal from '../../components/PayNowModal';
import useModalToggle from '../../custom_hooks/useModalToggle';
import { fetchGroupMembers } from '../../features/fetch/fetchSlice';
import { handlememberRole } from '../../features/auth/authSlice';
import PageActions from '../../components/PageActions';

export default function ActivutyOverview() {
    const dispatch = useDispatch()
    
    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/member/summary`);
    const { open, handleOpen, handleCancel } = useModalToggle();

    const { activeChama } = useSelector((state) => state.obj);
    const { user } = useSelector((state) => state.auth);
    const { members } = useSelector((state) => state.fetch);

    async function handleFetch() {
        await dispatch(fetchGroupMembers());
    }

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
        handleFetch()
    }, []);

    useEffect(() => {
        let loggedInMember = members?.find((item) => item?.gpmUsrId === user?.jti);
        dispatch(handlememberRole(loggedInMember?.gpmRoleName));
    }, [members]);

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Home</span>
                </div>
            ),
            href: '/#/dashboard',
        },
        {
            title: capitalize(activeChama?.custName),
        },
        {
            title: 'Your activity',
        },
    ];

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] pb-[5rem]">
                <div className="justify-between items-center white_card">
                    <BreadCrumb breadList={breadList} header={`Hello, ${capitalize(getFistName(user?.userName))}`} info={'Here’s an overview of your activity within the group'} />
                    <PageActions pageActions={''} text={'Contribute'} action={handleOpen} />
                </div>

                <div className="white_card flex-col">
                    <span className="heading_4">Summary</span>

                    {isLoading ? (
                        <CardLoading />
                    ) : (
                        <div className="flex w-full h-auto items-center overflow-x-auto overflow-y-hidden gap-[.75rem] mt-[1.69rem]">
                            <ActivitySummaryCard
                                amount={formatMoney(data?.totalSaving, 'KES')}
                                header={'Your contributions'}
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path
                                            opacity="0.2"
                                            d="M3.75 17.25C3.75 17.6478 3.90804 18.0294 4.18934 18.3107C4.47064 18.592 4.85218 18.75 5.25 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18V7.5C21 7.30109 20.921 7.11032 20.7803 6.96967C20.6397 6.82902 20.4489 6.75 20.25 6.75H5.25C4.85218 6.75 4.47064 6.59196 4.18934 6.31066C3.90804 6.02936 3.75 5.64782 3.75 5.25V17.25Z"
                                            fill="#111111"
                                        />
                                        <path
                                            d="M3.75 5.25V17.25C3.75 17.6478 3.90804 18.0294 4.18934 18.3107C4.47064 18.592 4.85218 18.75 5.25 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18V7.5C21 7.30109 20.921 7.11032 20.7803 6.96967C20.6397 6.82902 20.4489 6.75 20.25 6.75H5.25C4.85218 6.75 4.47064 6.59196 4.18934 6.31066C3.90804 6.02936 3.75 5.64782 3.75 5.25ZM3.75 5.25C3.75 4.85218 3.90804 4.47064 4.18934 4.18934C4.47064 3.90804 4.85218 3.75 5.25 3.75H18"
                                            stroke="#8EC68E"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M16.875 13.5C17.4963 13.5 18 12.9963 18 12.375C18 11.7537 17.4963 11.25 16.875 11.25C16.2537 11.25 15.75 11.7537 15.75 12.375C15.75 12.9963 16.2537 13.5 16.875 13.5Z"
                                            fill="#8EC68E"
                                        />
                                    </svg>
                                }
                                background={'#121212'}
                                color={'#fff'}
                            />

                            {/* <ActivitySummaryCard
								amount={formatMoney(data?.activeLoan, 'KES')}
								header={'Your active loan'}
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M14.25 10.5C14.25 10.6989 14.171 10.8897 14.0303 11.0303C13.8897 11.171 13.6989 11.25 13.5 11.25H10.5C10.3011 11.25 10.1103 11.171 9.96967 11.0303C9.82902 10.8897 9.75 10.6989 9.75 10.5C9.75 10.3011 9.82902 10.1103 9.96967 9.96967C10.1103 9.82902 10.3011 9.75 10.5 9.75H13.5C13.6989 9.75 13.8897 9.82902 14.0303 9.96967C14.171 10.1103 14.25 10.3011 14.25 10.5ZM21.75 6.75V18.75C21.75 19.1478 21.592 19.5294 21.3107 19.8107C21.0294 20.092 20.6478 20.25 20.25 20.25H3.75C3.35218 20.25 2.97064 20.092 2.68934 19.8107C2.40804 19.5294 2.25 19.1478 2.25 18.75V6.75C2.25 6.35218 2.40804 5.97064 2.68934 5.68934C2.97064 5.40804 3.35218 5.25 3.75 5.25H7.5V4.5C7.5 3.90326 7.73705 3.33097 8.15901 2.90901C8.58097 2.48705 9.15326 2.25 9.75 2.25H14.25C14.8467 2.25 15.419 2.48705 15.841 2.90901C16.2629 3.33097 16.5 3.90326 16.5 4.5V5.25H20.25C20.6478 5.25 21.0294 5.40804 21.3107 5.68934C21.592 5.97064 21.75 6.35218 21.75 6.75ZM9 5.25H15V4.5C15 4.30109 14.921 4.11032 14.7803 3.96967C14.6397 3.82902 14.4489 3.75 14.25 3.75H9.75C9.55109 3.75 9.36032 3.82902 9.21967 3.96967C9.07902 4.11032 9 4.30109 9 4.5V5.25ZM20.25 10.6509V6.75H3.75V10.6509C6.28146 12.0289 9.11783 12.7505 12 12.75C14.8822 12.7505 17.7185 12.0289 20.25 10.6509Z'
											fill='#003B5C'
										/>
									</svg>
								}
								background={'#fff'}
								color={'#121212'}
								footer={'5% interest | Due 12th June 2025'}
							/> */}

                            <ActivitySummaryCard
                                amount={formatMoney(data?.penalty, 'KES')}
                                header={'Penalties'}
                                icon={
                                    <div className="flex items-center gap-[.25rem]">
                                        <svg className="mt-[.25rem]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g filter="url(#filter0_d_1314_16828)">
                                                <circle cx="8" cy="4" r="4" fill="#FF4C4C" />
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_1314_16828" x="0" y="0" width="16" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4" />
                                                    <feGaussianBlur stdDeviation="2" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1314_16828" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1314_16828" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                        <span className="label_3 !text-[#FF4C4C]">View</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 19.625L10.925 18.55L16.75 12.75H4.375V11.25H16.75L10.925 5.45L12 4.375L19.625 12L12 19.625Z" fill="#FF4C4C" />
                                        </svg>
                                    </div>
                                }
                                background={'#fff'}
                                color={'#121212'}
                                url={'/activity/payment'}
                            />

                            <ActivitySummaryCard
                                amount={formatMoney(data?.totalArrears, 'KES')}
                                header={'Arrears'}
                                icon={
                                    <div className="flex items-center gap-[.25rem]">
                                        <svg className="mt-[.25rem]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g filter="url(#filter0_d_1314_16828)">
                                                <circle cx="8" cy="4" r="4" fill="#FF4C4C" />
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_1314_16828" x="0" y="0" width="16" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4" />
                                                    <feGaussianBlur stdDeviation="2" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1314_16828" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1314_16828" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                        <span className="label_3 !text-[#FF4C4C]">View</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 19.625L10.925 18.55L16.75 12.75H4.375V11.25H16.75L10.925 5.45L12 4.375L19.625 12L12 19.625Z" fill="#FF4C4C" />
                                        </svg>
                                    </div>
                                }
                                background={'#fff'}
                                color={'#121212'}
                                url={'/activity/payment'}
                            />
                        </div>
                    )}
                </div>

                <div className="white_card flex-col">
                    <span className="heading_4 mt-[3.16rem]">Notifications</span>

                    <div className="w-full grid grid-cols-1 gap-[1rem] mt-[1.25rem]">
                        {data?.totalArrears > 0 ? (
                            <>
                                <div className="w-full grid grid-cols-1 gap-[1rem] mt-[1.25rem]">
                                    <InAppNotificationBar
                                        color={'#FFB07C'}
                                        header={'You have arrears'}
                                        message={'Your currently have accrued areas of ' + formatMoney(data?.totalArrears, 'KES')}
                                        showPay={true}
                                        type="ARREARS"
                                        amount={data?.totalArrears}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="w-full grid grid-cols-1 gap-[1rem] mt-[1.25rem]">
                                <InAppNotificationBar color={'#8EC68E'} header={'Up to date'} message={'Your currently have KES 0 areas'} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="white_card flex-col">
                    <div className="flex justify-between items-center">
                        <span className="heading_4">Recent transactions</span>
                        <Link to="/activity/statements" className="flex items-center gap-[1rem]">
                            <span className="view_more_text">View more</span>
                            <div
                                style={{
                                    background: '#fff',
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                }}
                                className="w-[1.5rem] h-[1.5rem] flex justify-center items-center rounded-full"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path
                                        d="M3.85336 13.3535C3.8069 13.4 3.75175 13.4368 3.69106 13.462C3.63036 13.4871 3.5653 13.5001 3.49961 13.5001C3.43391 13.5001 3.36886 13.4871 3.30816 13.462C3.24746 13.4368 3.19231 13.4 3.14586 13.3535C3.0994 13.3071 3.06255 13.2519 3.03741 13.1912C3.01227 13.1305 2.99933 13.0655 2.99933 12.9998C2.99933 12.9341 3.01227 12.869 3.03741 12.8083C3.06255 12.7476 3.0994 12.6925 3.14586 12.646L7.79273 7.99979L3.14586 3.35354C3.05204 3.25972 2.99933 3.13247 2.99933 2.99979C2.99933 2.86711 3.05204 2.73986 3.14586 2.64604C3.23968 2.55222 3.36693 2.49951 3.49961 2.49951C3.63229 2.49951 3.75954 2.55222 3.85336 2.64604L8.85336 7.64604C8.89985 7.69248 8.93672 7.74762 8.96189 7.80832C8.98705 7.86902 9 7.93408 9 7.99979C9 8.0655 8.98705 8.13056 8.96189 8.19126C8.93672 8.25196 8.89985 8.3071 8.85336 8.35354L3.85336 13.3535ZM8.14586 3.35354L12.7927 7.99979L8.14586 12.646C8.0994 12.6925 8.06255 12.7476 8.03741 12.8083C8.01227 12.869 7.99933 12.9341 7.99933 12.9998C7.99933 13.0655 8.01227 13.1305 8.03741 13.1912C8.06255 13.2519 8.0994 13.3071 8.14586 13.3535C8.19231 13.4 8.24746 13.4368 8.30816 13.462C8.36885 13.4871 8.43391 13.5001 8.49961 13.5001C8.5653 13.5001 8.63036 13.4871 8.69105 13.462C8.75175 13.4368 8.8069 13.4 8.85336 13.3535L13.8534 8.35354C13.8998 8.3071 13.9367 8.25196 13.9619 8.19126C13.987 8.13056 14 8.0655 14 7.99979C14 7.93408 13.987 7.86902 13.9619 7.80832C13.9367 7.74762 13.8998 7.69248 13.8534 7.64604L8.85336 2.64604C8.75954 2.55222 8.63229 2.49951 8.49961 2.49951C8.36692 2.49951 8.23968 2.55222 8.14586 2.64604C8.05204 2.73986 7.99933 2.86711 7.99933 2.99979C7.99933 3.13247 8.05204 3.25972 8.14586 3.35354Z"
                                        fill="#343330"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </div>

                    <div className="w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]">
                        <div className="flex items-center gap-[1rem] w-full">
                            <div className="w-[13.625rem] ">
                                <Search text={'Search'} />
                            </div>

                            <div className="w-[12.5rem]">
                                <StatementFilter />
                            </div>
                        </div>

                        <ActivityRecentTransactionTable />
                    </div>
                </div>
            </div>

            <PayNowModal open={open} handleCancel={handleCancel} />
        </>
    );
}
