import { useNavigate } from 'react-router-dom';
import downArrow2 from '../../../assets/svg/downArrow2.svg';
import yellowDoubleArrowUp from '../../../assets/svg/yellowDoubleArrowUp.svg';
import { useDispatch } from 'react-redux';
import { handleActiveTab } from '../../../features/obj/objSlice';

export default function GroupActivitySummaryCard({ hasIcon, hasOtherIcon, hasPointerIcon, header, amount, link, handleToggleBalance, toggle, showBalance, background }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function handleNavigate() {
        switch (link) {
            case '/members/balance':
                if (header === 'Opening members balance') {
                    await dispatch(
                        handleActiveTab({
                            memberBalance: '2',
                        })
                    );
                } else if (header === 'Total members balance') {
                    await dispatch(
                        handleActiveTab({
                            memberBalance: '1',
                        })
                    );
                }
                await navigate(link);
                break;

            case '/group-activity/statements/arrears':
                await navigate(link);
                break;

            case '/group-activity/statements/contributions':
                if (header === 'Chama collection') {
                    await dispatch(
                        handleActiveTab({
                            statements: '1',
                        })
                    );
                } else if (header === 'Withdrawals') {
                    await dispatch(
                        handleActiveTab({
                            statements: '2',
                        })
                    );
                }
                await navigate(link);
                break;

            default:
                break;
        }
    }

    return (
        <>
            <button
                onClick={toggle ? () => handleToggleBalance() : handleNavigate}
                style={{
                    background: background,
                    border: '1px solid #E2E9EE',
                    padding: '1.75rem 0.8125rem 1.75rem 1.5rem',
                }}
                className="w-[15.1875rem] flex flex-col flex-shrink-0 flex-grow justify-center items-start h-[7rem] rounded-[0.5rem]"
            >
                <div className="w-full flex items-center gap-[1rem]">
                    <span className="activity_card_header col-span-2 text-[#121212] text-left">{header}</span>
                    {hasOtherIcon && !hasPointerIcon ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                opacity="0.2"
                                d="M3.75 17.25C3.75 17.6478 3.90804 18.0294 4.18934 18.3107C4.47064 18.592 4.85218 18.75 5.25 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18V7.5C21 7.30109 20.921 7.11032 20.7803 6.96967C20.6397 6.82902 20.4489 6.75 20.25 6.75H5.25C4.85218 6.75 4.47064 6.59196 4.18934 6.31066C3.90804 6.02936 3.75 5.64782 3.75 5.25V17.25Z"
                                fill="white"
                            />
                            <path
                                d="M3.75 5.25V17.25C3.75 17.6478 3.90804 18.0294 4.18934 18.3107C4.47064 18.592 4.85218 18.75 5.25 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18V7.5C21 7.30109 20.921 7.11032 20.7803 6.96967C20.6397 6.82902 20.4489 6.75 20.25 6.75H5.25C4.85218 6.75 4.47064 6.59196 4.18934 6.31066C3.90804 6.02936 3.75 5.64782 3.75 5.25ZM3.75 5.25C3.75 4.85218 3.90804 4.47064 4.18934 4.18934C4.47064 3.90804 4.85218 3.75 5.25 3.75H18"
                                stroke="#FFC263"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M16.875 13.5C17.4963 13.5 18 12.9963 18 12.375C18 11.7537 17.4963 11.25 16.875 11.25C16.2537 11.25 15.75 11.7537 15.75 12.375C15.75 12.9963 16.2537 13.5 16.875 13.5Z"
                                fill="#FFC263"
                            />
                        </svg>
                    ) : !hasOtherIcon && hasPointerIcon ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.2" d="M9 5.25H18V14.25L9 5.25Z" fill="#9645A4" fillOpacity="0.51" />
                            <path d="M3.75 20.25H20.25" stroke="#9645A4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.5 15.75L13.5 9.75" stroke="#9645A4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 5.25H18V14.25L9 5.25Z" stroke="#9645A4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    ) : null}
                </div>
                <span className="activity_card_text !text-[#121212]">{amount}</span>
                {hasIcon ? (
                    <div className="w-full flex justify-center">
                        <>
                            {showBalance ? (
                                <>
                                    <div className="w-[1rem] h-[1rem] flex justify-center items-center rounded-full">
                                        <img src={yellowDoubleArrowUp} alt="yellowDoubleArrowUp" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            background: 'rgba(233, 233, 233, 0.71)',
                                        }}
                                        className="w-[1rem] h-[1rem] flex justify-center items-center rounded-full"
                                    >
                                        <img src={downArrow2} alt="downArrow" />
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                ) : null}
            </button>
        </>
    );
}
