import { Descriptions } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, capitalize, capitalizeFirstLetter } from '../../../utils';
import { useEffect } from 'react';
import useFetch from '../../../custom_hooks/useFetch';
import CardLoading from '../../../components/CardLoading';
import useToggle from '../../../custom_hooks/useToggle';
import GeneralSettingsForm from '../chama_settings_form/GeneralSettingsForm';
import { clearEditChama } from '../../../features/obj/objSlice';

export default function GeneralSettingsTab() {
    const dispatch = useDispatch();
    const { show, toggle } = useToggle();

    const { isLoading, data } = useFetch(`${applicationUrl().url}/api/v1/member/group-mandates`);

    const { activeChama } = useSelector((state) => state.obj);

    const admins = data?.filter((item) => item?.gpmRoleName === 'ADMIN');
    const signatories = data?.filter((item) => item?.gpmRoleName !== 'ADMIN');

    async function handlefetch() {
        await dispatch(clearEditChama())
    }    

    const groupDeatils = [
        {
            key: '1',
            label: 'Group name',
            children: activeChama?.custName,
        },
        {
            key: '2',
            label: 'Group description',
            children: activeChama?.custGroupDescription,
        },
        {
            key: '3',
            label: 'Maximum no. of seats per member',
            children: activeChama?.custSeat,
        },
        {
            key: '4',
            label: 'Group features',
            children: activeChama?.custFeatures?.length ? activeChama?.custFeatures?.map((item, index) => <span key={index}>{capitalize(item?.csfFeature)},</span>) : <span>Savings</span>,
        },
        {
            key: '5',
            label: 'Group mandate',
            children: <span>{activeChama?.custGroupMandate} signatories for group activities</span>,
        },
        {
            key: '6',
            label: 'Signatories',
            children: (
                <div className="grid grid-cols-1 items-start gap-[.5rem]">
                    {signatories?.map((item, index) => (
                        <span key={index}>
                            {capitalizeFirstLetter(item?.gpmName)} ({capitalize(item?.gpmRoleName)})
                        </span>
                    ))}
                </div>
            ),
        },
        {
            key: '7',
            label: 'Administrators',
            children: (
                <div className="grid grid-cols-1 items-start gap-[.5rem]">
                    {admins?.map((item, index) => (
                        <span key={index}>{capitalizeFirstLetter(item?.gpmName)}</span>
                    ))}
                </div>
            ),
        },
    ];

    useEffect(() => {
        handlefetch();
    }, []);

    useEffect(() => {}, [data, activeChama]);

    if (isLoading) {
        return <CardLoading />;
    }

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] pb-[5rem]">
                {show ? (
                    <>
                        <GeneralSettingsForm toggle={toggle} />
                    </>
                ) : (
                    <>
                        <div className="w-full flex flex-col mt-[3.5rem]">
                            <div className="flex flex-col h-auto">
                                <div className="w-full flex justify-between pb-[2.25rem]">
                                    <div className="w-full flex flex-col gap-[.5rem]">
                                        <span className="heading_6">Group details</span>
                                        <span className="paragraph_2">View and update your group details</span>
                                    </div>
                                    <button onClick={toggle} type="button" className="action_btn">
                                        <div className="flex flex-shrink-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M5.15039 19.0031H6.40039L15.6504 9.75313L14.4254 8.50313L5.15039 17.7781V19.0031ZM18.8504 8.65312L15.4754 5.30313L16.5754 4.20312C16.9254 3.86979 17.3547 3.70312 17.8634 3.70312C18.3714 3.70312 18.7921 3.86979 19.1254 4.20312L20.1504 5.22812C20.4337 5.52812 20.5837 5.88213 20.6004 6.29013C20.6171 6.69879 20.4754 7.04479 20.1754 7.32812L18.8504 8.65312ZM17.7754 9.75313L7.02539 20.5031H3.65039V17.1281L14.4004 6.37812L17.7754 9.75313ZM15.0254 9.12813L14.4254 8.50313L15.6504 9.75313L15.0254 9.12813Z"
                                                    fill="#FFCD47"
                                                />
                                            </svg>
                                        </div>
                                        <span className="">Edit</span>
                                    </button>
                                </div>

                                <div className="w-full flex flex-col gap-[2rem]">
                                    <Descriptions className="custom_desc" colon={false} column={1} title={false} items={groupDeatils} layout="vertical" />
                                </div>
                            </div>

                            {/* <div className="flex flex-col w-full gap-[.38rem] mt-[1rem]">
                                <div className="flex justify-between pb-[.5rem] w-full">
                                    <span className="heading_6">Roles</span>
                                </div>

                                {roles.map((item, index) => {
                                    return (
                                        <div key={index} className="flex">
                                            <div className="flex flex-col gap-[.25rem]">
                                                <span className="ant-descriptions-item-content">
                                                    {capitalize(item?.roleName)} ({item?.roleName === 'MEMBER' ? 'Limited' : 'All permissions'})
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div> */}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
