import React from 'react';

export default function AboutCard({ header, text, icon }) {
    return (
        <>
            <div
                style={{
                    background: '#fff',
                }}
                className="w-full h-full xl:h-[14.875rem] py-[.62rem]"
            >
                <div className="flex flex-col gap-[1rem]">
                    <div
                        style={{
                            background: '#EBF13C',
                        }}
                        className="w-[3.125rem] h-[3.125rem] flex justify-center items-center rounded-full"
                    >
                        {icon}
                    </div>

                    <span className="lanidng_about_header">{header}</span>
                    <span className="lanidng_about_txt">{text}</span>
                </div>
            </div>
        </>
    );
}
