import { Spin, Table, Tooltip } from 'antd';
import moment from 'moment';
import { applicationUrl, customToast, formatMoney, getRandomColor } from '../../../utils';
import TableLoading from '../../../components/TableLoading';
import useFetchDispatch from '../../../custom_hooks/useFetchDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../features/save/saveSlice';
import DeclineApprovalModal from '../../../components/DeclineApprovalModal';
import { useEffect, useState } from 'react';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import info from '../../../assets/svg/Info.svg';
import info32 from '../../../assets/svg/Info32.svg';
import AllPurposeOtpModal from '../../../components/AllPurposeOtpModal';
import RequestSentModal from '../../../components/RequestSentModal';
import { fetchDisbursementApprovals } from '../../../features/approvals/approvalSlice';
import FundsTransferInfoModal from '../modals/FundsTransferInfoModal';

export default function DisbursementApprovalTable() {
    const { isLoading } = useFetchDispatch(fetchDisbursementApprovals);
    const { open, handleCancel, handleOpen } = useModalToggle();
    const veriyModal = useModalToggle();
    const success = useModalToggle();
    const moreInfo = useModalToggle();

    const dispatch = useDispatch();

    const { disbursements } = useSelector((state) => state.approval);
    const { saving } = useSelector((state) => state.save);
    const { user } = useSelector((state) => state.auth);

    const [saveObj, setsaveObj] = useState({});

    function handleInfo(item){
        setsaveObj(item)
        moreInfo.handleOpen()
    }

    async function handleClose() {
        await setsaveObj({});
        await veriyModal.handleCancel();
        await moreInfo.handleCancel()
        await handleCancel()
    }

    async function handleInitiateApproval(item) {
        setsaveObj(item);

        let data = {
            apvId: item?.apvId,
            saveUrl: '/api/v1/approval/sendOTP',
            url: applicationUrl().url,
        };

        const res = await dispatch(save(data));

        if (res?.payload?.success) {
            veriyModal.handleOpen();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'An error occured',
                bdColor: 'error',
                id: 735920
            });
        }
    }

    async function handleDecline(item) {
        setsaveObj({
            ...item,
            data: {
                apvId: item?.apvId,
                apvAmount: item?.apvAmount,
                apvType: 'DISBURSEMENT',
                apvRefId: item?.apvRefId,
                apvStatus: 'REJECT',
                saveUrl: '/api/v1/approval/approve',
                url: applicationUrl().url,
            },
        });
        handleOpen();
    }

    async function handleAccept(otp) {
        let data = {
            apvId: saveObj?.apvId,
            apvOTP: otp,
            saveUrl: '/api/v1/approval/verifyOTP',
            url: applicationUrl().url,
        };

        const res = await dispatch(save(data));

        if (res?.payload?.success) {
            await veriyModal.handleCancel();
            await success.handleOpen();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'An error occured',
                bdColor: 'error',
                id: 7363277
            });
        }
    }

    async function handleOkay() {
        await dispatch(fetchDisbursementApprovals());
        await success.handleCancel();
        await setsaveObj({});
    }

    useEffect(() => {}, [disbursements, saveObj]);

    if (isLoading) return <TableLoading />;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
            sorter: (a, b) => a - b,
        },
        {
            title: 'Initiator',
            dataIndex: 'member',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img
                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item?.gpmName)}&background=${getRandomColor()}&color=fff`}
                            className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                            alt="avatar"
                        />
                        <span>{item?.gpmName}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Amount (KES)',
            dataIndex: 'apvAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'More info',
            render: (item) => (
                <button onClick={() => handleInfo(item)} type="button">
                    <img src={info32} alt="info" />
                </button>
            ),
        },
        {
            title: 'Action',
            render: (item) => {
                if (item?.createdById === user?.jti) {
                    return (
                        <div className="w-full flex items-center gap-[.5rem]">
                            <Tooltip
                                arrow={false}
                                // open={true}
                                placement="bottom"
                                title={
                                    <>
                                        <div className="w-full flex flex-col p-[1rem] gap-[.5rem]">
                                            <div className="flex items-start gap-[.5rem]">
                                                <img src={info32} alt="info" />
                                                <span className="toolTip_header">Waiting for approval</span>
                                            </div>

                                            <span className="toolTip_text">Waiting for other group officials to approve.</span>
                                        </div>
                                    </>
                                }
                            >
                                <img src={info} alt="info" />
                            </Tooltip>
                            <span>Pending approval</span>
                        </div>
                    );
                }
                return (
                    <div className="flex items-center gap-[.75rem]">
                        <button disabled={saving} onClick={() => handleDecline(item)} className="modal_btn_light" type="button">
                            Decline
                        </button>
                        <button disabled={saving} onClick={() => handleInitiateApproval(item)} className="modal_btn_dark" type="button">
                            {item?.apvId === saveObj?.apvId && saving ? <Spin /> : 'Approve'}
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Table
                scroll={{
                    x: 1000,
                }}
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                rowKey="apvId"
                className="mt-[1.5rem] !w-full"
                columns={columns}
                dataSource={disbursements}
            />

            <DeclineApprovalModal
                open={open}
                handleFetch={fetchDisbursementApprovals}
                handleCancel={handleClose}
                text={`You are rejecting ${saveObj?.member?.gpmName}’s disbursement request. Give them a reason below.`}
                header={'Reject Disbursement'}
                saveObj={saveObj?.data}
                message={'apvDeclineReason'}
            />

            <AllPurposeOtpModal open={veriyModal.open} handleNext={handleAccept} handleCancel={handleClose} />

            <RequestSentModal
                open={success.open}
                handleOkay={handleOkay}
                header={'Disbursement request approved'}
                text={`You have approved ${saveObj?.member?.gpmName}’s request to make a disbursement of KES ${formatMoney(saveObj?.apvAmount, 'KES')}`}
            />

            <FundsTransferInfoModal open={moreInfo.open} handleOkay={handleClose} selectedItem={saveObj} />
        </>
    );
}
