import { Form, Input, Spin } from 'antd';
import { useSelector } from 'react-redux';
import image10 from '../../../assets/svg/ndovuLogoNew.svg';
import loginImg from '../../../assets/svg/login.svg';
import { useState } from 'react';
import ReactPasswordChecklist from 'react-password-checklist';
import { CheckCircleOutlined } from '@ant-design/icons';
import checkBox from '../../../assets/svg/Checkbox.svg';

export default function ResetPasswordStep() {

	const { authLoading } = useSelector((state) => state.auth);

    const [password, setpassword] = useState('');
	const [isValid, setisValid] = useState(false);

	return (
		<>
			<div className='flex w-full h-[100vh]'>
				<div className='w-full h-full flex justify-center items-center p-3 lg:p-[8.87rem] bg-white'>
					<div className='flex flex-col w-full'>
						<div className='flex items-center w-full gap-[.25rem]'>
							<img src={image10} className='logo !w-fit h-full' alt='image2' />
							<span className='heading_4'>Chamify Africa.</span>
						</div>

						<span className='heading_1 mt-[2.92rem]'>Reset password</span>
						<span className='paragraph_1 mb-[3.5rem] mt-[1.5rem]'>
                        Create a strong password below to secure your account
						</span>

						<div className='flex flex-col w-full lg:w-[29.9375rem]'>
							<Form.Item
								label='Password'
								name='secret'
								rules={[
									{
										required: true,
										message: 'Please input your new password',
									},
								]}>
								<Input.Password
									onChange={(e) => setpassword(e.target.value)}
									className='input'
									type='password'
								/>
							</Form.Item>

							<div className=''>
								<span className='paragraph_3 !text-[#616161]'>
									Password must contain:
								</span>
								<ReactPasswordChecklist
									className='text-darkBlue'
									rules={['minLength', 'capital', 'number']}
									minLength={8}
									value={password}
									onChange={(isValid) => {
										setisValid(isValid);
									}}
									messages={{
										minLength: 'A minimum of 8 characters',
										capital: 'At least one upper case letter (A-Z)',
										number: 'At least one number (0-9)',
									}}
									iconComponents={{
										ValidIcon: (
											<CheckCircleOutlined className='text-[1rem] mr-[.5rem] text-[green]' />
										),
										InvalidIcon: (
											<img
												className='mr-[.5rem]'
												src={checkBox}
												alt='checkBox'
											/>
										),
									}}
								/>
							</div>

							<div className='mt-[3.75rem] w-full flex justify-start items-start'>
								<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
									<button
										disabled={!isValid || authLoading}
										type='submit'
										className='cstm-btn-2'>
										{authLoading ? <Spin /> : 'Submit'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='hidden lg:flex w-full h-[100vh]'>
					<img
						src={loginImg}
						className='w-full h-full object-cover'
						alt='login'
					/>
				</div>
			</div>

		</>
	);
}
