import { Spin, Table, Tooltip } from 'antd';
import moment from 'moment';
import { applicationUrl, customToast, formatMoney, getRandomColor } from '../../../utils';
import TableLoading from '../../../components/TableLoading';
import useFetchDispatch from '../../../custom_hooks/useFetchDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../features/save/saveSlice';
import { fetchOpeningBalance } from '../../../features/approvals/approvalSlice';
import DeclineApprovalModal from '../../../components/DeclineApprovalModal';
import { useEffect, useState } from 'react';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import info from '../../../assets/svg/Info.svg';
import info32 from '../../../assets/svg/Info32.svg';
import OpeningBalanceInfoModal from '../../approvals/modals/OpeningBalanceInfoModal';

export default function MembersOpeningBalanceApprovalTable() {
    const { isLoading } = useFetchDispatch(fetchOpeningBalance);
    const { open, handleCancel, handleOpen } = useModalToggle();
    const dispatch = useDispatch();

    const moreInfo = useModalToggle();

    const { openingBalance } = useSelector((state) => state.approval);
    const { saving } = useSelector((state) => state.save);
    const { user } = useSelector((state) => state.auth);

    const [saveObj, setsaveObj] = useState({});

    function handleInfo(item){
        setsaveObj(item)
        moreInfo.handleOpen()
    }

    async function handleClose() {
        await setsaveObj({});
        await moreInfo.handleCancel()
        await handleCancel();
    }

    async function handleDecline(item) {
        setsaveObj({
            apvId: item?.apvId,
            apvAmount: item?.apvAmount,
            apvType: 'OPEN_BALANCE',
            apvRefId: item?.apvRefId,
            apvStatus: 'REJECT',
            saveUrl: '/api/v1/approval/approve',
            url: applicationUrl().url,
            member: item?.member,
        });
        handleOpen();
    }

    async function handleAccept(item) {
        let data = {
            apvId: item?.apvId,
            apvAmount: item?.apvAmount,
            apvType: 'OPEN_BALANCE',
            apvRefId: item?.apvRefId,
            apvStatus: 'ACCEPT',
            saveUrl: '/api/v1/approval/approve',
            url: applicationUrl().url,
        };

        const res = await dispatch(save(data));

        if (res?.payload?.success) {
            await dispatch(fetchOpeningBalance());
            await customToast({
                content: res?.payload?.messages?.message ?? 'Invite has been accepted.',
                bdColor: 'success',
                id: 7338999,
            });
            await dispatch(fetchOpeningBalance());
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'An error occured',
                bdColor: 'error',
                id: 88999777,
            });
        }
    }

    useEffect(() => {}, [openingBalance]);

    if (isLoading) return <TableLoading />;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
            sorter: (a, b) => a - b,
        },
        {
            title: 'Initiator',
            dataIndex: 'createdBy',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img
                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item)}&background=${getRandomColor()}&color=fff`}
                            className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                            alt="avatar"
                        />
                        <span>{item}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Opening balance (KES)',
            dataIndex: 'apvAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'More info',
            render: (item) => (
                <button onClick={() => handleInfo(item)} type="button">
                    <img src={info32} alt="info" />
                </button>
            ),
        },
        {
            title: 'Action',
            render: (item) => {
                if (item?.createdById === user?.jti) {
                    return (
                        <div className="w-full flex items-center gap-[.5rem]">
                            <Tooltip
                                arrow={false}
                                placement="bottom"
                                title={
                                    <>
                                        <div className="w-full flex flex-col p-[1rem] gap-[.5rem]">
                                            <div className="flex items-start gap-[.5rem]">
                                                <img src={info32} alt="info" />
                                                <span className="toolTip_header">Waiting for approval</span>
                                            </div>

                                            <span className="toolTip_text">Waiting for other group officials to approve.</span>
                                        </div>
                                    </>
                                }
                            >
                                <img src={info} alt="info" />
                            </Tooltip>
                            <span>Pending approval</span>
                        </div>
                    );
                }
                return (
                    <div className="flex items-center gap-[.75rem]">
                        <button disabled={saving} onClick={() => handleDecline(item)} className="modal_btn_light" type="button">
                            Decline
                        </button>
                        <button disabled={saving} onClick={() => handleAccept(item)} className="modal_btn_dark" type="button">
                            {item?.apvId === saveObj?.apvId && saving ? <Spin /> : 'Approve'}
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Table
                scroll={{
                    x: 1000,
                }}
                rowKey="apvId"
                className="mt-[1.5rem] !w-full"
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                columns={columns}
                dataSource={openingBalance}
            />

            <DeclineApprovalModal
                open={open}
                handleCancel={handleCancel}
                handleFetch={fetchOpeningBalance}
                text={`You are rejecting ${saveObj?.member?.gpmName}’s opening balance. Give them a reason below.`}
                header={'Reject opening balance'}
                saveObj={saveObj}
                message={'apvDeclineReason'}
            />

            <OpeningBalanceInfoModal open={moreInfo.open} handleOkay={handleClose} selectedItem={saveObj} />
        </>
    );
}
