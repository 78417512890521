import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import useFetch from '../../../custom_hooks/useFetch';
import { applicationUrl, formatMoney } from '../../../utils';
import { emptyChartArray } from '../../../data';

export default function AnalyticGraph() {
    const { data } = useFetch(`${applicationUrl().url}/api/v1/schedule/chart`);

    const formattedData = [];

    const monthlyTotals = {};

    const dailyTotals = {
        Mon: { time: 'Mon', collections: 0, arrears: 0 },
        Tue: { time: 'Tue', collections: 0, arrears: 0 },
        Wed: { time: 'Wed', collections: 0, arrears: 0 },
        Thu: { time: 'Thu', collections: 0, arrears: 0 },
        Fri: { time: 'Fri', collections: 0, arrears: 0 },
        Sat: { time: 'Sat', collections: 0, arrears: 0 },
        Sun: { time: 'Sun', collections: 0, arrears: 0 },
    };

    data?.forEach((entry) => {
        if ('Daily') {
            const createdDate = new Date(entry?.schDate);
            const dayOfWeek = createdDate.toLocaleString('en-US', {
                weekday: 'short',
            });

            if (dailyTotals[dayOfWeek]) {
                dailyTotals[dayOfWeek].collections += entry?.collections;

                dailyTotals[dayOfWeek].arrears += entry?.arrears;
            }
        } else {
            const time = entry.contMonthName;
            const amount = entry.schContAmount;
            const status = entry.schStatus;

            if (!monthlyTotals[time]) {
                monthlyTotals[time] = { time, collections: 0, arrears: 0 };
            }

            monthlyTotals[time].collections += amount;

            if (status === 'PENDING') {
                monthlyTotals[time].arrears += amount;
            }
        }
    });

    if ('Daily') {
        for (const day in dailyTotals) {
            formattedData.push(dailyTotals[day]);
        }
    } else {
        for (const month in monthlyTotals) {
            formattedData.push(monthlyTotals[month]);
        }
    }

    const formatCurrency = (tick) => {
        if (tick >= 1e9) {
            return (tick / 1e9).toFixed() + 'B';
        } else if (tick >= 1e6) {
            return (tick / 1e6).toFixed() + 'M';
        } else if (tick >= 1e3) {
            return (tick / 1e3).toFixed() + 'K';
        } else {
            return tick;
        }
    };

    const CustomTooltip = ({ payload }) => {
        if (payload && payload?.length) {
            const { collections, arrears } = payload[0]?.payload;
            return (
                <div className="custom-tooltip">
                    <span className="paragraph_3">
                        Collections: <span className="ml-[.1rem] font-medium">{formatMoney(collections, 'KES')}</span>
                    </span>
                    <span className="paragraph_3">
                        Arrears: <span className="ml-[.1rem] font-medium">{formatMoney(arrears, 'KES')}</span>
                    </span>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="flex items-center justify-between">
                    <span className="heading_4">Analytics</span>
                    <div className="flex items-center gap-[2rem]">
                        <div className="flex items-center gap-[.75rem]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#8EC68E" />
                            </svg>
                            <span className="graph_icon_text">Collections</span>
                        </div>
                        <div className="flex items-center gap-[.75rem]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <circle cx="6" cy="6" r="6" fill="#FFB07C" />
                            </svg>
                            <span className="graph_icon_text">Arrears</span>
                        </div>

                        {/* <Select
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='17'
									height='18'
									viewBox='0 0 17 18'
									fill='none'>
									<path
										d='M11.5404 7.08203L8.49949 10.1229L5.45861 7.08203L4.45703 8.08361L8.49949 12.1261L12.5419 8.08361L11.5404 7.08203Z'
										fill='#0D163A'
									/>
								</svg>
							}
							className='graph_select'
							placeholder={capitalize(activeChama?.custContributionFrequency)}
							options={[
								{
									label: capitalize(activeChama?.custContributionFrequency),
									value: activeChama?.custContributionFrequency,
								},
							]}
						/> */}
                    </div>
                </div>
                <div className="mt-[2.13rem] h-[19rem]">
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={formattedData?.length ? formattedData : emptyChartArray}>
                            <XAxis dataKey="time" />
                            <YAxis tickFormatter={formatCurrency} />
                            <Tooltip content={<CustomTooltip />} />
                            <Area type="monotone" dataKey="collections" stroke="#000" fill="#C8FAD1" fillOpacity={0.9} />
                            <Area type="monotone" dataKey="arrears" stroke="#000" fill="#FAD6C8" fillOpacity={0.3} />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    );
}
