import React from 'react';
import { applicationUrl, formatMoney } from '../../../utils';
import useFetch from '../../../custom_hooks/useFetch';
import CardLoading from '../../../components/CardLoading';
import ActivitySummaryCard from '../../activities/cards/ActivitySummaryCard';
import InAppNotificationBar from '../../../components/InAppNotificationBar';
import Search from '../../../components/Search';
import StatementFilter from '../../statements/StatementFilter';
import ContributionTypeRecentTransactionsTable from '../tables/ContributionTypeRecentTransactionsTable';
import { useSelector } from 'react-redux';

export default function ContributionsTypeOverviewTab() {
    const { activeContributionType } = useSelector((state) => state.obj);

    const { isLoading, data } = useFetch(`${applicationUrl().url}/api/v1/member/sub-contribution/summary?walletCode=${activeContributionType?.rdnCwCode}`);    

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] pb-[5rem]">
                <div className="white_card flex-col">
                    <span className="heading_4">Summary</span>

                    {isLoading ? (
                        <CardLoading />
                    ) : (
                        <div className="flex w-full h-auto items-center overflow-x-auto overflow-y-hidden gap-[.75rem] mt-[1.69rem]">
                            <ActivitySummaryCard
                                amount={formatMoney(data?.contribution, 'KES')}
                                header={'Contributions'}
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path
                                            opacity="0.2"
                                            d="M3.75 17.25C3.75 17.6478 3.90804 18.0294 4.18934 18.3107C4.47064 18.592 4.85218 18.75 5.25 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18V7.5C21 7.30109 20.921 7.11032 20.7803 6.96967C20.6397 6.82902 20.4489 6.75 20.25 6.75H5.25C4.85218 6.75 4.47064 6.59196 4.18934 6.31066C3.90804 6.02936 3.75 5.64782 3.75 5.25V17.25Z"
                                            fill="#111111"
                                        />
                                        <path
                                            d="M3.75 5.25V17.25C3.75 17.6478 3.90804 18.0294 4.18934 18.3107C4.47064 18.592 4.85218 18.75 5.25 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18V7.5C21 7.30109 20.921 7.11032 20.7803 6.96967C20.6397 6.82902 20.4489 6.75 20.25 6.75H5.25C4.85218 6.75 4.47064 6.59196 4.18934 6.31066C3.90804 6.02936 3.75 5.64782 3.75 5.25ZM3.75 5.25C3.75 4.85218 3.90804 4.47064 4.18934 4.18934C4.47064 3.90804 4.85218 3.75 5.25 3.75H18"
                                            stroke="#8EC68E"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M16.875 13.5C17.4963 13.5 18 12.9963 18 12.375C18 11.7537 17.4963 11.25 16.875 11.25C16.2537 11.25 15.75 11.7537 15.75 12.375C15.75 12.9963 16.2537 13.5 16.875 13.5Z"
                                            fill="#8EC68E"
                                        />
                                    </svg>
                                }
                                background={'#121212'}
                                color={'#fff'}
                            />

                            <ActivitySummaryCard
                                amount={formatMoney(data?.withdrawals, 'KES')}
                                header={'Withdrawals'}
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.2" d="M9 5.25H18V14.25L9 5.25Z" fill="#9645A4" fillOpacity="0.51" />
                                        <path d="M3.75 20.25H20.25" stroke="#9645A4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.5 15.75L13.5 9.75" stroke="#9645A4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9 5.25H18V14.25L9 5.25Z" stroke="#9645A4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                }
                                background={'#fff'}
                                color={'#121212'}
                            />

                            {/* <ActivitySummaryCard amount={formatMoney(data?.penalty, 'KES')} header={'Penalties'} background={'#fff'} color={'#121212'} url={'/activity/arrears'} /> */}

                            <ActivitySummaryCard amount={formatMoney(data?.totalArrears, 'KES')} header={'Arrears'} background={'#FFEFE5'} color={'#121212'} />
                        </div>
                    )}
                </div>

                <div className="white_card flex-col">
                    <span className="heading_4 mt-[3.16rem]">Notifications</span>

                    <div className="w-full grid grid-cols-1 gap-[1rem] mt-[1.25rem]">
                        {data?.totalArrears > 0 ? (
                            <>
                                <div className="w-full grid grid-cols-1 gap-[1rem]">
                                    <InAppNotificationBar
                                        color={'#FFB07C'}
                                        header={'Pending Arrears'}
                                        message={'Arreas have accrued of ' + formatMoney(data?.totalArrears, 'KES')}
                                        type="ARREARS"
                                        amount={data?.totalArrears}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="w-full grid grid-cols-1 gap-[1rem]">
                                <InAppNotificationBar color={'#8EC68E'} header={'Up to date'} message={'No pending arrears'} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="white_card flex-col">
                    <div className="flex justify-between items-center">
                        <span className="heading_4">Recent transactions</span>
                    </div>

                    <div className="w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]">
                        <div className="flex items-center gap-[1rem] w-full">
                            <div className="w-[13.625rem] ">
                                <Search text={'Search'} />
                            </div>

                            <div className="w-[12.5rem]">
                                <StatementFilter />
                            </div>
                        </div>

                        <ContributionTypeRecentTransactionsTable />
                    </div>
                </div>
            </div>
        </>
    );
}
