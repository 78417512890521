import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Form, Input, Spin } from 'antd';
import { handleLoginSession, handleUser, login, logout } from '../../features/auth/authSlice';
import { useEffect, useRef, useState } from 'react';
import { applicationUrl, customToast } from '../../utils';
import image10 from '../../assets/svg/ndovuLogoNew.svg';
import loginImg from '../../assets/svg/login.svg';
import { jwtDecode } from 'jwt-decode';
import CustomAllPurposeOtpModal from '../../components/CustomAllPurposeOtpModal';
import useModalToggle from '../../custom_hooks/useModalToggle';
import { save } from '../../features/save/saveSlice';
import axiosInstance from '../../instance';

export default function Login() {
    const { open, handleCancel, handleOpen } = useModalToggle();

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authLoading, isActive } = useSelector((state) => state.auth);

    const formRef = useRef(null);

    const [otpObj, setotpObj] = useState({});

    function handleClose() {
        axiosInstance.defaults.headers.common = { Authorization: `` };
        dispatch(logout());
        setotpObj({});
        localStorage.clear();
        handleCancel();
    }

    async function handleDecodeToken(payload) {
        try {
            let tokenObj = jwtDecode(payload?.accessToken);

            await dispatch(handleUser(tokenObj));
            await localStorage.setItem('token', payload?.accessToken);

            if (tokenObj?.hasTwoFactorAuth === true) {
                await setotpObj({
                    otpType: 'BOTH',
                });
                await handleOpen();
            } else {
                await dispatch(handleLoginSession(true));
                await form.resetFields();
                await customToast({
                    content: 'Login successful',
                    bdColor: 'success',
                    id: 73636633333,
                });
            }
        } catch (error) {            
            handleClose();
            await customToast({
                content: 'We are experiencing technical issues. Please try again later',
                bdColor: 'error',
                id: 99100,
            });
        }
    }

    const onFinish = async (data) => {
        const res = await dispatch(login(data));
        
        if (res?.payload?.success) {            
            axiosInstance.defaults.headers.common = { Authorization: `Bearer ${res?.payload?.accessToken}` };
            await handleDecodeToken(res?.payload);            
        } else {
            await customToast({
                content: 'Invalid credentials',
                bdColor: 'error',
                id: 111111111111,
            });
        }
    };

    async function handleNext(cancatenatedString) {
        const saveObj = {
            otpType: 'VERIFY',
            secret: cancatenatedString,
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/custom-verify-update',
        };
        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await dispatch(handleLoginSession(true));
            await form.resetFields();
            await customToast({
                content: 'Login successful',
                bdColor: 'success',
                id: 986522222222,
            });
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'Could not verify email. Please try again later',
                bdColor: 'error',
                id: 763782989,
            });
        }
    }

    useEffect(() => {}, [otpObj, isActive]);

    useEffect(() => {
        if (!isActive) {
            dispatch(logout());
        }
    }, []);

    if (isActive) {
        return <Navigate to="/onboarding" replace />;
    }

    return (
        <>
            <div className="flex w-full h-[100vh]">
                <div className="w-full h-full flex justify-center items-center p-3 lg:p-[8.87rem] bg-white">
                    <div className="flex flex-col w-full">
                        <Link to="/home" className="flex items-center w-full gap-[.5rem]">
                            <img src={image10} className="logo !w-fit h-full" alt="image2" />
                            <span className="heading_4">Chamify Africa.</span>
                        </Link>

                        <span className="heading_1 mt-[2.12rem]">Log in</span>
                        <span className="paragraph_1 mt-[1.5rem]">Welcome. Enter your details to log in.</span>

                        <Form
                            layout="vertical"
                            ref={formRef}
                            name="control-ref"
                            onFinish={onFinish}
                            style={{
                                maxWidth: '100%',
                                marginTop: '3rem',
                                width: '26.25rem',
                            }}
                            form={form}
                        >
                            <Form.Item
                                className="table-form-item"
                                name="nationalId"
                                label="National ID"
                                rules={[
                                    {
                                        required: true,
                                        message: 'National ID is required to login',
                                    },
                                ]}
                            >
                                <Input className="input" />
                            </Form.Item>

                            <Form.Item
                                className="table-form-item mt-[1.75rem]"
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password',
                                    },
                                ]}
                            >
                                <Input.Password className="input" />
                            </Form.Item>

                            <div className="flex justify-end mt-[.5rem]">
                                <Link to="/forgot-password" className="label_2">
                                    Forgot Password?
                                </Link>
                            </div>

                            <div className="flex justify-center w-[10.625rem] mt-[1.63rem]">
                                <button className="cstm-btn-2" type="submit" disabled={authLoading ? true : false}>
                                    {authLoading ? <Spin /> : 'Log in'}
                                </button>
                            </div>

                            <div className="w-full flex mt-[2.94rem]">
                                <span className="label_2">Don’t have an account? </span>
                                <button className="label_2 !text-blu ml-[.1rem] underline" onClick={() => navigate('/registration')}>
                                    Register
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>

                <div className="hidden lg:flex w-full h-[100vh]">
                    <img src={loginImg} className="w-full h-full object-cover" alt="login" />
                </div>
            </div>

            <CustomAllPurposeOtpModal open={open} handleCancel={handleClose} text={'Enter OTP sent to your email and phone number'} handleNext={handleNext} otpObj={otpObj} />
        </>
    );
}
