export const collectionIntervals = [
    {
        label: 'Daily',
        value: 'Daily',
    },
    {
        label: 'Weekly',
        value: 'Weekly',
    },
    {
        label: 'Bi-weekly (Every two weeks)',
        value: 'Biweekly',
    },
    {
        label: 'Monthly',
        value: 'Monthly',
    },
    {
        label: 'Quarterly',
        value: 'Quarterly',
    },
    {
        label: 'Every 6 months',
        value: 'Semi Annually',
    },
    {
        label: 'Annually',
        value: 'Annually',
    },
];

export const weeeklyContributions = [
    {
        label: 'Every Monday',
        value: 'Every Monday',
    },
    {
        label: 'Every Tuesday',
        value: 'Every Tuesday',
    },
    {
        label: 'Every Wednesday',
        value: 'Every Wednesday',
    },
    {
        label: 'Every Thursday',
        value: 'Every Thursday',
    },
    {
        label: 'Every Friday',
        value: 'Every Friday',
    },
    {
        label: 'Every Saturday',
        value: 'Every Saturday',
    },
    {
        label: 'Every Sunday',
        value: 'Every Sunday',
    },
];

export const biweeeklyContributions = [
    {
        label: 'Every Other Monday',
        value: 'Every Other Monday',
    },
    {
        label: 'Every Other Tuesday',
        value: 'Every Other Tuesday',
    },
    {
        label: 'Every Other Wednesday',
        value: 'Every Other Wednesday',
    },
    {
        label: 'Every Other Thursday',
        value: 'Every Other Thursday',
    },
    {
        label: 'Every Other Friday',
        value: 'Every Other Friday',
    },
    {
        label: 'Every Other Saturday',
        value: 'Every Other Saturday',
    },
    {
        label: 'Every Other Sunday',
        value: 'Every Other Sunday',
    },
];

export const roleTypes = [
    {
        label: 'Chairman',
        value: 'CHAIRMAN',
    },
    {
        label: 'Treasurer',
        value: 'TREASURER',
    },
    {
        label: 'Secretary',
        value: 'SECRETARY',
    },
];

export const reportType = [
    {
        label: 'Member',
        value: 'INDIVIDUAL',
    },
    {
        label: 'Chama Group',
        value: 'ORGANIZATION',
    },
];

export const booleanType = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];

export const groupMandates = [
    {
        label: 'At least 2 signatories',
        value: 2,
    },
    {
        label: 'At least 3 signatories',
        value: 3,
    },
];

export const emptyChartArray = [
    { time: 'Mon', collections: 0, arrears: 0 },
    { time: 'Tue', collections: 0, arrears: 0 },
    { time: 'Wed', collections: 0, arrears: 0 },
    { time: 'Thu', collections: 0, arrears: 0 },
    { time: 'Fri', collections: 0, arrears: 0 },
    { time: 'Sat', collections: 0, arrears: 0 },
    { time: 'Sun', collections: 0, arrears: 0 },
];

export const transactionType = [
    {
        label: 'MPESA',
        value: 'MPESA',
    },
    {
        label: 'BANK',
        value: 'BANK',
    },
];

export const debitNoteType = [
    {
        label: 'Arrear',
        value: 'Arrear',
    },
    {
        label: 'Penalty',
        value: 'Penalty',
    },
    {
        label: 'Loan',
        value: 'Loan',
    },
    {
        label: 'Other',
        value: 'Other',
    },
];

export const membersRoleColors = [
    {
        label: 'CHAIRMAN',
        value: '#E7F4E7',
    },
    {
        label: 'TREASURER',
        value: '#F1E7F3',
    },
    {
        label: 'SECRETARY',
        value: 'rgba(147, 189, 205, 0.22)',
    },
    {
        label: 'ADMIN',
        value: '#FDFFE9',
    },
    {
        label: 'MEMBER',
        value: '#F4F4F4',
    },
];

export const debitNoteTypeColor = [
    {
        label: 'Arrear',
        value: '#E7F4E7',
    },
    {
        label: 'ARREARS',
        value: '#E7F4E7',
    },
    {
        label: 'Penalty',
        value: 'rgba(255, 227, 230, 0.69)',
    },
    {
        label: 'Loan',
        value: '#EEFCFF',
    },
    {
        label: 'Other',
        value: '#FDFFE9',
    },
];

export const statusColor = [
    {
        label: 'PENDING',
        value: '#E2E9EE',
    },
    {
        label: 'PENDING_OTP_VALIDATION',
        value: '#E2E9EE',
    },
    {
        label: 'PENDING_APPROVAL',
        value: '#FFA17F',
    },

    {
        label: 'ONGOING',
        value: 'rgba(147, 205, 147, 0.22)',
    },
    {
        label: 'PAID',
        value: 'rgba(147, 205, 147, 0.5)',
    },
    {
        label: 'SUCCESS',
        value: 'rgba(147, 205, 147, 0.5)',
    },

    {
        label: 'FAILED',
        value: 'rgba(255, 76, 94, 0.69)',
    },
];

export const memberMenu = [
    {
        key: '4',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clipPath="url(#clip0_863_35748)">
                    <path
                        d="M13.25 4.95076C13.25 6.71849 11.7949 8.15152 10 8.15152C8.20507 8.15152 6.75 6.71849 6.75 4.95076C6.75 3.18303 8.20507 1.75 10 1.75C11.7949 1.75 13.25 3.18303 13.25 4.95076Z"
                        stroke="#FFCD47"
                        strokeWidth="1.50926"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M3.75 16.3996C3.75 13.748 5.93261 11.5985 8.625 11.5985H11.375C14.0674 11.5985 16.25 13.748 16.25 16.3996C16.25 17.2835 15.5225 18 14.625 18H5.375C4.47754 18 3.75 17.2835 3.75 16.3996Z"
                        stroke="#FFCD47"
                        strokeWidth="1.50926"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_863_35748">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                </defs>
            </svg>
        ),
        label: 'Your activity',
        children: [
            {
                label: 'Overview',
                key: '/activity/overview',
            },
            {
                label: 'Statements',
                key: '/activity/statements',
            },
            {
                label: 'Payment',
                key: '/activity/payment',
            },
            {
                label: 'Reimbursement',
                key: '/activity/reimbursement',
            },
        ],
    },
    {
        key: '2',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path
                    d="M16.0109 11.047H15.9626L15.9411 11.0902L12.9895 16.9949C12.9895 16.9949 12.9895 16.9949 12.9895 16.9949C12.944 17.0858 12.8742 17.1622 12.7877 17.2155C12.7013 17.2689 12.6017 17.2971 12.5001 17.297H12.5H12.4707C12.3653 17.2915 12.2638 17.2555 12.1783 17.1935C12.0924 17.1311 12.0265 17.045 11.9886 16.9458L7.52223 5.21923L7.45558 5.04425L7.3781 5.21471L4.87263 10.7264L4.87263 10.7264C4.82919 10.822 4.75917 10.903 4.67093 10.9599C4.58269 11.0167 4.47996 11.047 4.375 11.047C4.37499 11.047 4.37498 11.047 4.37497 11.047H1.875C1.72996 11.047 1.59086 10.9894 1.4883 10.8869C1.38574 10.7843 1.32812 10.6452 1.32812 10.5002C1.32812 10.3551 1.38574 10.216 1.4883 10.1135C1.59086 10.0109 1.72996 9.95329 1.875 9.95329H3.97266H4.02296L4.04378 9.90749L7.00237 3.3989L7.00238 3.39887C7.04701 3.3006 7.11973 3.21773 7.21137 3.1607C7.303 3.10367 7.40947 3.07503 7.51735 3.07839C7.62524 3.08175 7.72971 3.11696 7.81762 3.17959C7.90553 3.24222 7.97294 3.32945 8.01137 3.43032L12.5043 15.2272L12.5677 15.3935L12.6472 15.2343L15.1355 10.257L15.1356 10.2568C15.1809 10.1657 15.2507 10.089 15.3371 10.0354C15.4236 9.98178 15.5233 9.95334 15.625 9.95329H18.125C18.27 9.95329 18.4091 10.0109 18.5117 10.1135C18.6143 10.216 18.6719 10.3551 18.6719 10.5002C18.6719 10.6452 18.6143 10.7843 18.5117 10.8869C18.4091 10.9894 18.27 11.047 18.125 11.047H16.0109Z"
                    fill="#FFCD47"
                    stroke="#FFCD47"
                    strokeWidth="0.15625"
                />
            </svg>
        ),
        label: 'Group activity',
        children: [
            {
                label: 'Overview',
                key: '/group/overview',
            },
            {
                label: 'Contribution types',
                key: '/contribution/types',
            },
            {
                label: 'Members',
                key: '/members/overview',
            },
        ],
    },
];

export const validMemberPaths = [
    '/dashboard',
    '/join-chama',
    '/group/overview',
    '/contribution/types',
    '/members/overview',
    '/activity/overview',
    '/activity/statements',
    '/activity/payment',
    '/activity/reimbursement',
];

export const mainMenu = [
    {
        key: '4',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clipPath="url(#clip0_863_35748)">
                    <path
                        d="M13.25 4.95076C13.25 6.71849 11.7949 8.15152 10 8.15152C8.20507 8.15152 6.75 6.71849 6.75 4.95076C6.75 3.18303 8.20507 1.75 10 1.75C11.7949 1.75 13.25 3.18303 13.25 4.95076Z"
                        stroke="#FFCD47"
                        strokeWidth="1.50926"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M3.75 16.3996C3.75 13.748 5.93261 11.5985 8.625 11.5985H11.375C14.0674 11.5985 16.25 13.748 16.25 16.3996C16.25 17.2835 15.5225 18 14.625 18H5.375C4.47754 18 3.75 17.2835 3.75 16.3996Z"
                        stroke="#FFCD47"
                        strokeWidth="1.50926"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_863_35748">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                </defs>
            </svg>
        ),
        label: 'Your activity',
        children: [
            {
                label: 'Overview',
                key: '/activity/overview',
            },
            {
                label: 'Statements',
                key: '/activity/statements',
            },
            {
                label: 'Payment',
                key: '/activity/payment',
            },
            {
                label: 'Reimbursement',
                key: '/activity/reimbursement',
            },
            {
                label: 'Loans',
                key: '/activity/loans',
                disabled: true,
            },
        ],
    },
    {
        key: '2',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path
                    d="M16.0109 11.047H15.9626L15.9411 11.0902L12.9895 16.9949C12.9895 16.9949 12.9895 16.9949 12.9895 16.9949C12.944 17.0858 12.8742 17.1622 12.7877 17.2155C12.7013 17.2689 12.6017 17.2971 12.5001 17.297H12.5H12.4707C12.3653 17.2915 12.2638 17.2555 12.1783 17.1935C12.0924 17.1311 12.0265 17.045 11.9886 16.9458L7.52223 5.21923L7.45558 5.04425L7.3781 5.21471L4.87263 10.7264L4.87263 10.7264C4.82919 10.822 4.75917 10.903 4.67093 10.9599C4.58269 11.0167 4.47996 11.047 4.375 11.047C4.37499 11.047 4.37498 11.047 4.37497 11.047H1.875C1.72996 11.047 1.59086 10.9894 1.4883 10.8869C1.38574 10.7843 1.32812 10.6452 1.32812 10.5002C1.32812 10.3551 1.38574 10.216 1.4883 10.1135C1.59086 10.0109 1.72996 9.95329 1.875 9.95329H3.97266H4.02296L4.04378 9.90749L7.00237 3.3989L7.00238 3.39887C7.04701 3.3006 7.11973 3.21773 7.21137 3.1607C7.303 3.10367 7.40947 3.07503 7.51735 3.07839C7.62524 3.08175 7.72971 3.11696 7.81762 3.17959C7.90553 3.24222 7.97294 3.32945 8.01137 3.43032L12.5043 15.2272L12.5677 15.3935L12.6472 15.2343L15.1355 10.257L15.1356 10.2568C15.1809 10.1657 15.2507 10.089 15.3371 10.0354C15.4236 9.98178 15.5233 9.95334 15.625 9.95329H18.125C18.27 9.95329 18.4091 10.0109 18.5117 10.1135C18.6143 10.216 18.6719 10.3551 18.6719 10.5002C18.6719 10.6452 18.6143 10.7843 18.5117 10.8869C18.4091 10.9894 18.27 11.047 18.125 11.047H16.0109Z"
                    fill="#FFCD47"
                    stroke="#FFCD47"
                    strokeWidth="0.15625"
                />
            </svg>
        ),
        label: 'Group activity',
        children: [
            {
                label: 'Overview',
                key: '/group/overview',
            },
            {
                label: 'Contribution types',
                key: '/contribution/types',
            },
            {
                label: 'Debit notes',
                key: '/debit/notes',
            },
            {
                label: 'Accounts summary',
                key: '/account/summary',
            },
            {
                key: '9',
                label: 'Funds transfer',
                children: [
                    {
                        label: 'Settlement',
                        key: '/funds-transfer/settlement',
                    },
                    {
                        label: 'Disbursement',
                        key: '/funds-transfer/disbursement',
                    },
                    {
                        label: 'Accounts transfer',
                        key: '/funds-transfer/accounts-transfer',
                    },
                ],
            },
            {
                key: '6',
                label: 'Members management',
                children: [
                    {
                        label: 'Overview',
                        key: '/members/overview',
                    },
                    {
                        label: 'Opening balance',
                        key: '/members/balance',
                    },
                    // {
                    //     label: 'Outstanding balance',
                    //     key: '/members/outstanding-balance',
                    // },
                ],
            },
            {
                label: 'Statements',
                key: '7',
                children: [
                    {
                        label: 'Statements summary',
                        key: '/statements/summary',
                    },
                    {
                        label: 'All statements',
                        key: '/group-activity/statements',
                    },
                    {
                        label: 'Contribution statements',
                        key: '/group-activity/statements/contributions',
                    },
                    {
                        label: 'Arrears statements',
                        key: '/group-activity/statements/arrears',
                    },
                ],
            },
            {
                key: '8',
                label: 'Approvals',
                children: [
                    {
                        label: 'Members',
                        key: '/approvals/members',
                    },
                    {
                        label: 'Settlement of funds',
                        key: '/approvals/settlement',
                    },
                    {
                        label: 'Disbursements',
                        key: '/approvals/disbursements',
                    },
                    {
                        label: 'Reimbursements',
                        key: '/approvals/reimbursement',
                    },
                    {
                        label: 'Account transfers',
                        key: '/approvals/account-transfer',
                    },
                    {
                        label: 'Opening balances',
                        key: '/approvals/opening-balance',
                    },
                    {
                        label: 'Settlement accounts',
                        key: '/approvals/settlement-accounts',
                    },
                ],
            },
            {
                label: 'Loans',
                key: '3',
                disabled: true,
                children: [
                    {
                        label: 'Loan summary',
                        key: '/loans/summary',
                    },
                    {
                        label: 'Loan requests',
                        key: '/loans/requests',
                    },
                ],
            },
            {
                key: '/chama/settings',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <g clipPath="url(#clip0_1939_15162)">
                            <path
                                d="M11.3447 3.22727C11.3447 2.4114 10.6833 1.75 9.86742 1.75H8.88258C8.0667 1.75 7.4053 2.4114 7.4053 3.22727V3.93093C7.4053 4.36963 6.87489 4.58933 6.56468 4.27912L6.06714 3.78158C5.49023 3.20467 4.55487 3.20467 3.97796 3.78158L3.28157 4.47797C2.70465 5.05488 2.70465 5.99024 3.28157 6.56715L3.7791 7.06468C4.08931 7.37489 3.86961 7.9053 3.4309 7.9053L2.72727 7.9053C1.9114 7.9053 1.25 8.5667 1.25 9.38257L1.25 10.3674C1.25 11.1833 1.9114 11.8447 2.72727 11.8447H3.43087C3.86958 11.8447 4.08928 12.3751 3.77907 12.6853L3.28156 13.1828C2.70465 13.7597 2.70465 14.6951 3.28156 15.272L3.97795 15.9684C4.55486 16.5453 5.49022 16.5453 6.06713 15.9684L6.56468 15.4708C6.87489 15.1606 7.4053 15.3803 7.4053 15.819V16.5227C7.4053 17.3386 8.0667 18 8.88258 18H9.86742C10.6833 18 11.3447 17.3386 11.3447 16.5227V15.8191C11.3447 15.3804 11.8751 15.1607 12.1853 15.4709L12.6829 15.9685C13.2598 16.5454 14.1951 16.5454 14.7721 15.9685L15.4684 15.2721C16.0454 14.6952 16.0454 13.7598 15.4684 13.1829L14.9709 12.6853C14.6607 12.3751 14.8804 11.8447 15.3191 11.8447H16.0227C16.8386 11.8447 17.5 11.1833 17.5 10.3674V9.38258C17.5 8.5667 16.8386 7.9053 16.0227 7.9053L15.319 7.9053C14.8803 7.9053 14.6606 7.37489 14.9708 7.06468L15.4684 6.56709C16.0453 5.99018 16.0453 5.05482 15.4684 4.47791L14.772 3.78152C14.1951 3.20461 13.2598 3.20461 12.6829 3.78152L12.1853 4.27907C11.8751 4.58928 11.3447 4.36957 11.3447 3.93087V3.22727Z"
                                stroke="#404040"
                                strokeWidth="1.50926"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12.0833 9.875C12.0833 11.3708 10.8708 12.5833 9.375 12.5833C7.87923 12.5833 6.66667 11.3708 6.66667 9.875C6.66667 8.37923 7.87923 7.16667 9.375 7.16667C10.8708 7.16667 12.0833 8.37923 12.0833 9.875Z"
                                stroke="#404040"
                                strokeWidth="1.50926"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1939_15162">
                                <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                ),
                label: 'Settings',
            },
        ],
    },
];
