import React from 'react';
import appPreview from '../../../assets/img/appPreview.png';
import stressFreeLove from '../../../assets/svg/stressFreeLove.svg';
import stressFreeFolder from '../../../assets/svg/stressFreeFolder.svg';
import stressFreePlant from '../../../assets/svg/stressFreePlant.svg';
import stressFreePeople from '../../../assets/svg/stressFreePeople.svg';

const stressFreeItems = [
    {
        id: 1,
        text: 'Confidence that your savings and investments are always on track.',
        icon: stressFreeLove,
    },
    {
        id: 2,
        text: 'Financial tools built for everyday people, not just the pros.',
        icon: stressFreeFolder,
    },
    {
        id: 3,
        text: 'A complete picture of your financial growth, wherever you are.',
        icon: stressFreePlant,
    },
    {
        id: 4,
        text: 'Our support team ready to assist, whenever you need help.',
        icon: stressFreePeople,
    },
];

export default function StressFreeComponent() {
    return (
        <>
            <div
                style={{
                    background: 'linear-gradient(180deg, #FEFEFE 25%, #F1F6FF 25%)',
                }}
                className="w-full flex flex-col gap-[2rem] pb-[6rem]"
            >
                <div className="w-full flex justify-center items-center">
                    <img src={appPreview} alt="chamify preview" className="w-[48rem] h-auto" />
                </div>

                <div className="w-full flex justify-center items-center flex-col mt-[2.5rem] xl:mt-0 px-[.75rem] xl:px-0">
                    <span className="landing_stress_free">A stress-free way to manage your savings.</span>
                    <span className="mt-[2rem] landing_stress_txt">Your Chamify account gets you access to this and more:</span>

                    <div className="mt-[3rem] grid grid-cols-1 xl:grid-cols-2 gap-[4rem]">
                        {stressFreeItems.map((item) => {
                            return (
                                <div key={item.id} className="w-full flex items-center gap-[2rem]">
                                    <img src={item.icon} alt="stressFreeFolder" />
                                    <span className="w-[21.5625rem] max-w-full">{item.text}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
