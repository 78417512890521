import React, { useRef, useState } from 'react';
import { Form, Input, Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, customToast } from '../../../utils';
import { save } from '../../../features/save/saveSlice';
import ReactPasswordChecklist from 'react-password-checklist';
import { CheckCircleOutlined } from '@ant-design/icons';
import checkBox from '../../../assets/svg/Checkbox.svg';

export default function UpdateUserPasswordModal({ open, handleCancel, header, handleUpdate, currentStatus, text }) {
    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const formRef = useRef(null);

    const { saving } = useSelector((state) => state.save);

    const [password, setpassword] = useState('');
    const [isValid, setisValid] = useState(false);

    const onFinish = async (data) => {
        if (currentStatus === 'CHANGE_PASSWORD') {
            await handleChangePassword(data);
        } else {
            const saveObj = {
                ...data,
                url: applicationUrl().url,
                saveUrl: '/api/v2/users/initiate-password-reset',
            };

            const res = await dispatch(save(saveObj));

            if (res?.payload?.success) {
                await form.resetFields();
                await handleUpdate(data);
                await setisValid(false);
                await setpassword('');
                return;
            } else {
                customToast({
                    content: res?.payload?.messages?.message ?? 'We cannot process your request at the moment',
                    bdColor: 'error',
                    id: 999990000,
                });
            }
        }
    };

    async function handleChangePassword(data) {
        const saveObj = {
            ...data,
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/update-password',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await customToast({
                content: 'Password updated',
                bdColor: 'success',
                id: 825,
            });
            await setisValid(false);
            await setpassword('');
            await handleClose();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'Could not process your request. Please try again later',
                bdColor: 'error',
                id: 8662,
            });
        }
    }

    async function handleClose() {
        await handleCancel();
        await setisValid(false);
        await setpassword('');
        await form.resetFields();
    }

    return (
        <>
            <Modal centered className="update-user-details-modal" title={header} open={open} footer={false}>
                <div className="px-[2.94rem] pt-[5rem] w-full flex flex-col">
                    <span className="heading_3">{header}</span>
                    <div className="w-full flex flex-col gap-[.5rem] mt-[1.25rem]">
                        <Form
                            layout="vertical"
                            ref={formRef}
                            name="updateUserDetailsModal"
                            onFinish={onFinish}
                            style={{
                                maxWidth: '100%',
                                width: '100%',
                            }}
                            initialValues={{
                                memberIds: [],
                            }}
                            form={form}
                        >
                            <Form.Item
                                label={text}
                                name="secret"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Field is required',
                                    },
                                ]}
                            >
                                <Input.Password onChange={(e) => setpassword(e.target.value)} className="input" />
                            </Form.Item>

                            <div className="">
                                <span className="paragraph_3 !text-[#616161]">Password must contain:</span>
                                <ReactPasswordChecklist
                                    className="text-darkBlue"
                                    rules={['minLength', 'capital', 'number']}
                                    minLength={8}
                                    value={password}
                                    onChange={(isValid) => {
                                        setisValid(isValid);
                                    }}
                                    messages={{
                                        minLength: 'A minimum of 8 characters',
                                        capital: 'At least one upper case letter (A-Z)',
                                        number: 'At least one number (0-9)',
                                    }}
                                    iconComponents={{
                                        ValidIcon: <CheckCircleOutlined className="text-[1rem] mr-[.5rem] text-[green]" />,
                                        InvalidIcon: <img className="mr-[.5rem]" src={checkBox} alt="checkBox" />,
                                    }}
                                />
                            </div>

                            <div className="flex items-center justify-end mt-[5rem] w-full">
                                <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
                                    <button disabled={saving} onClick={handleClose} className="cstm-btn" type="button">
                                        Cancel
                                    </button>

                                    <button disabled={!isValid || saving} className="cstm-btn-2" type="submit">
                                        {saving ? <Spin /> : 'Next'}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
}
