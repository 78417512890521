import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const initialState = {
    saving: false,
};

export const save = createAsyncThunk('saveSlice/save', async (data) => {
    let saveUrl = data.saveUrl;
    let url = data.url;

    delete data.saveUrl;
    delete data.url;

    const res = await axiosInstance.post(`${url}${saveUrl}`, data).then((res) => res.data);
    return res;
});

export const saveFile = createAsyncThunk('saveSlice/saveFile', async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const url = process.env.REACT_APP_API_BASE_URL;

    const res = await axiosInstance
        .post(`${url}/v1/api/att`, formData, {
            // .post(`https://demobackend.chamify.africa/v1/api/att`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => res.data);

    return res;
});

export const update = createAsyncThunk('saveSlice/update', async (data) => {
    let saveUrl = data.saveUrl;
    let url = data.url;

    delete data.saveUrl;
    delete data.url;

    const res = await axiosInstance.put(`${url}${saveUrl}`, data?.data).then((res) => res.data);
    return res;
});

export const deleteEntry = createAsyncThunk('saveSlice/deleteEntry', async (data) => {
    let saveUrl = data.saveUrl;
    let url = data.url;

    delete data.saveUrl;
    delete data.url;

    const res = await axiosInstance.delete(`${url}${saveUrl}`).then((res) => res.data);
    return res;
});

export const saveSlice = createSlice({
    name: 'save',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveFile.pending, (state) => {
                state.saving = true;
            })
            .addCase(saveFile.fulfilled, (state) => {
                state.saving = false;
            })
            .addCase(saveFile.rejected, (state) => {
                state.saving = false;
            })

            .addCase(deleteEntry.pending, (state) => {
                state.saving = true;
            })
            .addCase(deleteEntry.fulfilled, (state) => {
                state.saving = false;
            })
            .addCase(deleteEntry.rejected, (state) => {
                state.saving = false;
            })

            .addCase(update.pending, (state) => {
                state.saving = true;
            })
            .addCase(update.fulfilled, (state) => {
                state.saving = false;
            })
            .addCase(update.rejected, (state) => {
                state.saving = false;
            })

            .addCase(save.pending, (state) => {
                state.saving = true;
            })
            .addCase(save.fulfilled, (state) => {
                state.saving = false;
            })
            .addCase(save.rejected, (state) => {
                state.saving = false;
            });
    },
});

export default saveSlice.reducer;
export const {} = saveSlice.actions;
