import React from 'react';
import { Link } from 'react-router-dom';
import creteGroup from '../../../assets/img/createGroup.png';
import invest from '../../../assets/img/invest.png';
import merryGoRound from '../../../assets/img/merryGoRound.png';
import quickLoans from '../../../assets/img/quickLoans.png';

export default function LandingPageImageComponent() {
    const features = [
        {
            image: creteGroup,
            height: '23.6875rem',
        },
        {
            image: invest,
            height: '15.625rem',
        },
        {
            image: merryGoRound,
            height: '15.625rem',
        },
        {
            image: quickLoans,
            height: '23.6875rem',
        },
    ];

    return (
        <div className="app">
            <div className="w-full flex justify-center items-center">
                <Link to="/registration" type="button" className="register_btn mb-[2rem] xl:mb-0">
                    <span className="landing_menu_item_login !text-[#141711]">Get started now</span>
                    <div className="w-[2.5rem] h-[2.5rem] bg-[#141711] flex justify-center items-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                            <g clipPath="url(#clip0_1775_738)">
                                <path d="M1.25 7H15M15 7L9.5 1.5M15 7L9.5 12.5" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1775_738">
                                    <rect width="16" height="13" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </Link>
            </div>
            <div className="w-full flex flex-col lg:flex-row flex-wrap justify-center items-baseline gap-[1.88rem]">
                {features.map((item, index) => (
                    <div key={index} className="feature-card">
                        <div
                            className="feature-image"
                            style={{
                                backgroundImage: `url(${item.image})`,
                                height: item.height,
                                width: '20.1875rem',
                            }}
                        ></div>
                    </div>
                ))}
            </div>
        </div>
    );
}
