import React, { useState } from 'react';
import getStarted from '../../../assets/img/getStarted.png';
import useToggle from '../../../custom_hooks/useToggle';
import { Link } from 'react-router-dom';

const upArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
        <g clipPath="url(#clip0_1775_877)">
            <path d="M9.66667 22.2688V1.4355M9.66667 1.4355L1.3333 9.7688M9.66667 1.4355L18 9.7688" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1775_877">
                <rect width="19" height="23" fill="white" transform="matrix(-1 0 0 -1 19 23.2688)" />
            </clipPath>
        </defs>
    </svg>
);

const downArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
        <g clipPath="url(#clip0_1775_891)">
            <path d="M9.66658 1.8833V22.7167M9.66658 22.7167L17.9999 14.3834M9.66658 22.7167L1.33325 14.3834" stroke="#141711" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1775_891">
                <rect width="19" height="23" fill="white" transform="translate(0 0.800049)" />
            </clipPath>
        </defs>
    </svg>
);

const getStartedItems = [
    {
        id: 1,
        header: 'Sign up and set up your account',
        text: 'Once you create an account, you can start saving on your own or form a group to save with others.',
    },
    {
        id: 2,
        header: 'Create or Join a group',
        text: 'Create your own group and invite members to participate in common goals or join already active groups',
    },
    {
        id: 3,
        header: 'Save towards your goals',
        text: 'You have the ability to have your own personal savings that can be customized to suite all your needs or save as a group.',
    },
];

export default function GetStartedComponent() {
    const { show, toggle } = useToggle(true);

    const [activeId, setactiveId] = useState(1);

    function handleActiveId(val) {
        setactiveId(val);
        if (show) {
            return;
        }
        toggle();
    }

    return (
        <>
            <div className="w-full flex items-start gap-[5.56rem] bg-[#F9F4F1] py-[7rem] px-[.75rem] xl:px-[6.94rem]">
                <img
                    style={{
                        width: '32.1875rem',
                        height: '38.3125rem',
                    }}
                    src={getStarted}
                    alt="get started"
                    className="hidden xl:flex"
                />
                <div className="flex flex-col items-start">
                    <span className="landing_get_started">Get started</span>
                    <span className="landing_get_quick_easy mt-[1.98rem]">
                        It’s quick and easy <br className='hidden xl:flex' /> to start saving.
                    </span>

                    <ol className="mt-[2.97rem] w-full get_started_list">
                        {getStartedItems.map((item) => {
                            return (
                                <div className="w-full xl:w-[36.125rem] max-w-full" key={item.id}>
                                    <li onClick={() => handleActiveId(item.id)} className="w-full flex justify-between items-center pointer">
                                        <span className="landing_get_started_list_item">{item.header}</span>
                                        {show && activeId === item.id ? upArrow : downArrow}
                                    </li>
                                    {show && activeId === item.id ? <span className="landing_get_started_list_item_desc">{item.text}</span> : null}
                                    <div className="w-full h-[0.125rem] bg-[#48533B] mt-[1.26rem] mb-[1.78rem]"></div>
                                </div>
                            );
                        })}
                    </ol>

                    <div className='mt-[4rem]'>
                        <Link to="/registration" type="button" className="register_btn">
                            <span className="landing_menu_item_login !text-[#141711]">Get started now</span>
                            <div className="w-[2.5rem] h-[2.5rem] bg-[#141711] flex justify-center items-center rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                                    <g clipPath="url(#clip0_1775_738)">
                                        <path d="M1.25 7H15M15 7L9.5 1.5M15 7L9.5 12.5" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1775_738">
                                            <rect width="16" height="13" fill="white" transform="translate(0 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
