import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import userImg from '../assets/svg/user.svg';
import moment from 'moment';
import { handleOpenKeys, handleSelectedKeys, toggleCollapse } from '../features/setup/setupSlice';
import { formatFilePath, getRandomColor } from '../utils';
import { mainMenu, memberMenu } from '../data';

const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
        items2.forEach((item) => {
            if (item.key) {
                key[item.key] = level;
            }
            if (item.children) {
                func(item.children, level + 1);
            }
        });
    };
    func(items1);
    return key;
};

export default function SideBarOpen({ isMobile }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { stateOpenKeys, stateSelectedKeys } = useSelector((state) => state.setup);
    const { user, memberRole } = useSelector((state) => state.auth);
    const { chamaActive, activeChama } = useSelector((state) => state.obj);

    const handleNavigation = async (e) => {
        await dispatch(handleSelectedKeys([e.key]));
        if (isMobile) await dispatch(toggleCollapse());
        await navigate(e.key);
    };

    const handleOpenChange = (openKeys) => {
        const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
        if (currentOpenKey !== undefined) {
            const repeatIndex = openKeys.filter((key) => key !== currentOpenKey).findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
            dispatch(handleOpenKeys(openKeys.filter((_, index) => index !== repeatIndex).filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])));
            dispatch(handleSelectedKeys(openKeys.filter((_, index) => index !== repeatIndex).filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])));
        } else {
            dispatch(handleOpenKeys(openKeys));
            dispatch(handleSelectedKeys(openKeys));
        }
    };

    // const updatedMemberMenu = memberMenu.map((menuItem) => {
    //     if (menuItem.label === 'Your activity') {
    //         return {
    //             ...menuItem,
    //             label: `${capitalize(getFistName(user?.userName)) || 'Your'}'s activity`, // Use the user's first name or default to 'Your'
    //         };
    //     }
    //     return menuItem;
    // });

    // const updatedMenu = mainMenu.map((menuItem) => {
    //     if (menuItem.label === 'Your activity') {
    //         return {
    //             ...menuItem,
    //             label: `${capitalize(getFistName(user?.userName)) || 'Your'}'s activity`, // Use the user's first name or default to 'Your'
    //         };
    //     }
    //     return menuItem;
    // });

    const items = chamaActive
        ? memberRole === 'MEMBER'
            ? memberMenu
            : mainMenu
        : [
              {
                  key: '1',
                  icon: <img src={userImg} alt="user" />,
                  label: 'Home',
                  children: [
                      {
                          label: 'Chama',
                          key: '/dashboard',
                      },
                      {
                          label: 'Personal investment',
                          key: '/personal/investment',
                          disabled: true,
                      },
                  ],
              },
              {
                  key: '/profile/settings',
                  icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                          <g clipPath="url(#clip0_1939_15162)">
                              <path
                                  d="M11.3447 3.22727C11.3447 2.4114 10.6833 1.75 9.86742 1.75H8.88258C8.0667 1.75 7.4053 2.4114 7.4053 3.22727V3.93093C7.4053 4.36963 6.87489 4.58933 6.56468 4.27912L6.06714 3.78158C5.49023 3.20467 4.55487 3.20467 3.97796 3.78158L3.28157 4.47797C2.70465 5.05488 2.70465 5.99024 3.28157 6.56715L3.7791 7.06468C4.08931 7.37489 3.86961 7.9053 3.4309 7.9053L2.72727 7.9053C1.9114 7.9053 1.25 8.5667 1.25 9.38257L1.25 10.3674C1.25 11.1833 1.9114 11.8447 2.72727 11.8447H3.43087C3.86958 11.8447 4.08928 12.3751 3.77907 12.6853L3.28156 13.1828C2.70465 13.7597 2.70465 14.6951 3.28156 15.272L3.97795 15.9684C4.55486 16.5453 5.49022 16.5453 6.06713 15.9684L6.56468 15.4708C6.87489 15.1606 7.4053 15.3803 7.4053 15.819V16.5227C7.4053 17.3386 8.0667 18 8.88258 18H9.86742C10.6833 18 11.3447 17.3386 11.3447 16.5227V15.8191C11.3447 15.3804 11.8751 15.1607 12.1853 15.4709L12.6829 15.9685C13.2598 16.5454 14.1951 16.5454 14.7721 15.9685L15.4684 15.2721C16.0454 14.6952 16.0454 13.7598 15.4684 13.1829L14.9709 12.6853C14.6607 12.3751 14.8804 11.8447 15.3191 11.8447H16.0227C16.8386 11.8447 17.5 11.1833 17.5 10.3674V9.38258C17.5 8.5667 16.8386 7.9053 16.0227 7.9053L15.319 7.9053C14.8803 7.9053 14.6606 7.37489 14.9708 7.06468L15.4684 6.56709C16.0453 5.99018 16.0453 5.05482 15.4684 4.47791L14.772 3.78152C14.1951 3.20461 13.2598 3.20461 12.6829 3.78152L12.1853 4.27907C11.8751 4.58928 11.3447 4.36957 11.3447 3.93087V3.22727Z"
                                  stroke="#404040"
                                  strokeWidth="1.50926"
                                  strokeLinejoin="round"
                              />
                              <path
                                  d="M12.0833 9.875C12.0833 11.3708 10.8708 12.5833 9.375 12.5833C7.87923 12.5833 6.66667 11.3708 6.66667 9.875C6.66667 8.37923 7.87923 7.16667 9.375 7.16667C10.8708 7.16667 12.0833 8.37923 12.0833 9.875Z"
                                  stroke="#404040"
                                  strokeWidth="1.50926"
                                  strokeLinejoin="round"
                              />
                          </g>
                          <defs>
                              <clipPath id="clip0_1939_15162">
                                  <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                              </clipPath>
                          </defs>
                      </svg>
                  ),
                  label: 'Settings',
              },
          ];

    const levelKeys = getLevelKeys(items);

    useEffect(() => {}, [chamaActive, activeChama, stateOpenKeys, stateSelectedKeys, memberRole]);    

    return (
        <>
            <div
                style={{
                    borderRadius: '0.875rem 0.875rem 0rem 0rem',
                }}
                className="flex flex-col h-[95vh] flex-shrink-0 w-[15.75rem] bg-white"
            >
                {chamaActive ? (
                    <>
                        <div className="w-full max-w-sm bg-mainBg rounded-lg">
                            <div className="card">
                                <div className="cover-photo">
                                    <Link to="/dashboard" className="absolute top-[1rem] left-[1.06rem]">
                                        <div className="bg-white flex justify-center items-center p-[.25rem] w-[2rem] h-[2rem] rounded-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M13.7069 6.79266L8.70688 1.79266C8.51936 1.60527 8.26511 1.5 8.00001 1.5C7.7349 1.5 7.48065 1.60527 7.29313 1.79266L2.29313 6.79266C2.19982 6.88528 2.12586 6.99552 2.07553 7.11698C2.02521 7.23844 1.99954 7.36869 2.00001 7.50016L2.00001 13.5002C2.00001 13.6328 2.05268 13.7599 2.14645 13.8537C2.24022 13.9475 2.3674 14.0002 2.50001 14.0002L6.50001 14.0002C6.63261 14.0002 6.75979 13.9475 6.85356 13.8537C6.94733 13.7599 7.00001 13.6328 7.00001 13.5002L7.00001 10.0002H9.00001L9.00001 13.5002C9.00001 13.6328 9.05268 13.7599 9.14645 13.8537C9.24022 13.9475 9.3674 14.0002 9.50001 14.0002H13.5C13.6326 14.0002 13.7598 13.9475 13.8536 13.8537C13.9473 13.7599 14 13.6328 14 13.5002V7.50016C14.0005 7.36869 13.9748 7.23844 13.9245 7.11698C13.8742 6.99552 13.8002 6.88528 13.7069 6.79266ZM13 13.0002H10V9.50016C10 9.36755 9.94733 9.24038 9.85356 9.14661C9.75979 9.05284 9.63261 9.00016 9.50001 9.00016L6.50001 9.00016C6.3674 9.00016 6.24022 9.05284 6.14645 9.14661C6.05268 9.24038 6.00001 9.36755 6.00001 9.50016L6.00001 13.0002H3.00001L3.00001 7.50016L8.00001 2.50016L13 7.50016V13.0002Z"
                                                    fill="#343330"
                                                />
                                            </svg>
                                        </div>
                                    </Link>
                                    <img
                                        loading="lazy"
                                        style={{
                                            zIndex: '1000',
                                        }}
                                        src={
                                            activeChama?.custProfileImage
                                                ? formatFilePath(activeChama?.custProfileImage)
                                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(activeChama?.custName)}&background=${getRandomColor()}&color=fff`
                                        }
                                        alt="chamaImg"
                                        className="profile z-40 rounded"
                                    />
                                </div>

                                <div className="flex flex-col items-center pb-[2.19rem] px-[.25rem]">
                                    <span className="heading_5 mt-[19%]">{activeChama?.custName}</span>
                                    <span className="profile_email mt-[.81rem] !text-[#98A2B3]">{`Joined ${moment(activeChama?.joinedDate).format('Do MMM YYYY')}`}</span>

                                    <div
                                        style={{
                                            padding: '0.375rem 0rem 0.5625rem 0rem',
                                        }}
                                        className="rounded-[2.5rem] bg-[#F5F5F5] w-[8.25rem] flex justify-center items-center mt-[1.25rem]"
                                    >
                                        <span className="label_4">{activeChama?.custMemberCount} members</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="w-full max-w-sm bg-mainBg rounded-lg">
                            <div className="card">
                                <div className="cover-photo">
                                    {/* <UserOutlined className="profile-icon" /> */}
                                    <img
                                        loading="lazy"
                                        style={{
                                            zIndex: '1000',
                                        }}
                                        src={
                                            user?.usrLogo
                                                ? formatFilePath(user?.usrLogo)
                                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.userName)}&background=${getRandomColor()}&color=fff`
                                        }
                                        alt="userImg"
                                        className="profile z-40 rounded"
                                    />
                                </div>

                                <div className="flex flex-col items-center pb-[2.19rem]">
                                    <span className="heading_5 mt-[19%]">{user?.userName}</span>
                                    <span className="profile_email mt-[1.44rem]">{user?.email}</span>
                                    <span className="paragraph_2 mt-[.94rem]">+{user?.phone}</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="w-full overflow-y-auto relative">
                    <Menu
                        onClick={handleNavigation}
                        style={{
                            width: '100%',
                        }}
                        mode="inline"
                        items={items}
                        theme="light"
                        openKeys={stateOpenKeys}
                        defaultSelectedKeys={['1', '2', '/dashboard']}
                        defaultOpenKeys={['/dashboard']}
                        selectedKeys={stateSelectedKeys}
                        onOpenChange={handleOpenChange}
                    />
                </div>
            </div>
        </>
    );
}
