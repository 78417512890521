import { Tabs } from 'antd';
import React from 'react';
import trackSavings from '../../../assets/img/trackSavings.png';

export default function InsightsComponent() {
    const items = [
        {
            key: '1',
            label: 'Savings tracker',
            children: (
                <div className="flex flex-col w-[23.8125rem] max-w-full gap-[1.5rem]">
                    <span className="landing_insight_sub_header">Personalized progress tracker</span>
                    <span className="landing_insight_text">
                        Stay on top of your goals with real-time insights into your savings progress. The app analyzes your contributions and suggests ways to accelerate reaching your targets, so you
                        never lose sight of your financial milestones.
                    </span>

                    <div className="flex flex-col gap-[1.25rem] w-full">
                        <span className="landing_insight_pro_tip">pro tip:</span>
                        <span className="landing_insight_text">According to research, people who track their financial goals regularly are 2x more likely to achieve them.</span>
                    </div>
                </div>
            ),
        },
        {
            key: '2',
            label: 'Financial Patterns',
            children: (
                <div className="flex flex-col w-[23.8125rem] max-w-full gap-[1.5rem]">
                    <span className="landing_insight_sub_header">Observe your savings patterns</span>
                    <span className="landing_insight_text">
                        Understand your financial behavior with detailed reports on your saving habits. The app helps identify areas to improve and provides recommendations to boost your savings
                        potential based on your unique patterns.
                    </span>

                    <div className="flex flex-col gap-[1.25rem] w-full">
                        <span className="landing_insight_pro_tip">pro tip:</span>
                        <span className="landing_insight_text">Those who track spending and set savings goals tend to increase their savings rate by up to 30%.</span>
                    </div>
                </div>
            ),
        },
        {
            key: '3',
            label: 'Performance',
            children: (
                <div className="flex flex-col w-[23.8125rem] max-w-full gap-[1.5rem]">
                    <span className="landing_insight_sub_header">Group Performance Analytics</span>
                    <span className="landing_insight_text">
                        Get insights into your group's overall performance, identify trends, and see how everyone’s contributions are helping the group reach shared financial targets faster.
                    </span>

                    <div className="flex flex-col gap-[1.25rem] w-full">
                        <span className="landing_insight_pro_tip">pro tip:</span>
                        <span className="landing_insight_text">Research on group savings programs shows that 60% of participants reach their financial goals faster when they save with others.</span>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="w-full flex flex-col gap-[4.25rem] px-[1.88rem]">
                <span className="landing_insight_header text-center">
                    Get insights to keep you <br className='hidden xl:flex' /> accountable.
                </span>

                <div className="w-full xl:w-fit flex items-start gap-[2.25rem]">
                    <Tabs className="landing_tab w-full" tabPosition={window.innerWidth > 1024 ? 'left' : 'top'} items={items} />
                    <div className="flex flex-shrink-0">
                        <img src={trackSavings} className='hidden xl:flex' alt="track your savings" />
                    </div>
                </div>
            </div>
        </>
    );
}
