import { Link } from 'react-router-dom';
import aboutAfrica from '../../../assets/img/aboutAfrica.png';
import aboutReady from '../../../assets/img/aboutReady.png';
import AboutCard from '../cards/AboutCard';

export default function AboutComponent() {
    const featureItems = [
        {
            id: 1,
            header: 'Encouraging Financial Growth',
            text: 'We believe that saving is the foundation of financial freedom. Our mission is to make saving simple, accessible, and empowering for everyone.',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path
                        d="M8.81331 19.229V16.104M12.98 14.0207V19.229M17.1466 19.229V11.9373M7.25081 12.4582L8.77644 10.6783C9.7669 9.52267 11.2963 8.97967 12.7937 9.252C14.8676 9.629 16.9943 8.96467 18.4847 7.47423L18.7091 7.24984M11.105 22.354H14.855C17.9797 22.354 19.5421 22.354 20.6374 21.5583C20.9911 21.3013 21.3022 20.9902 21.5592 20.6364C22.355 19.5412 22.355 17.9788 22.355 14.854V11.104C22.355 7.97923 22.355 6.41684 21.5592 5.32156C21.3022 4.96784 20.9911 4.65676 20.6374 4.39977C19.5421 3.604 17.9797 3.604 14.855 3.604H11.105C7.98021 3.604 6.41781 3.604 5.32254 4.39977C4.96881 4.65676 4.65774 4.96784 4.40075 5.32156C3.60498 6.41684 3.60498 7.97923 3.60498 11.104V14.854C3.60498 17.9788 3.60498 19.5412 4.40075 20.6364C4.65774 20.9902 4.96881 21.3013 5.32254 21.5583C6.41781 22.354 7.98021 22.354 11.105 22.354Z"
                        stroke="#141711"
                        strokeWidth="1.5625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            ),
        },
        {
            id: 2,
            header: 'Community & Collaboration',
            text: 'Invest as a community, and achieve shared financial goals. We are building stronger, more connected communities in Africa.',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path
                        d="M10.9209 18.7572C10.5402 19.782 11.2983 20.8723 12.3916 20.8723H19.2912C19.8616 20.8723 20.1467 20.8723 20.3587 20.837C21.8039 20.5967 22.7666 19.2123 22.4885 17.7739C22.4478 17.5629 22.3485 17.2956 22.1499 16.7609C21.9846 16.3159 21.902 16.0934 21.8058 15.8992C21.1719 14.618 19.9253 13.7513 18.5036 13.6031C18.2881 13.5806 18.0507 13.5806 17.576 13.5806H15.716C15.4942 13.5806 15.3832 13.5806 15.2729 13.5864C14.9954 13.6011 14.72 13.6436 14.4509 13.7132C14.3441 13.7408 14.2383 13.7741 14.0267 13.841M9.17706 8.89311C8.02647 8.89311 7.09373 7.96037 7.09373 6.80978C7.09373 5.65918 8.02647 4.72644 9.17706 4.72644C10.3276 4.72644 11.2604 5.65918 11.2604 6.80978C11.2604 7.96037 10.3276 8.89311 9.17706 8.89311ZM16.9896 10.9764C15.839 10.9764 14.9062 10.0437 14.9062 8.89311C14.9062 7.74252 15.839 6.80978 16.9896 6.80978C18.1401 6.80978 19.0729 7.74252 19.0729 8.89311C19.0729 10.0437 18.1401 10.9764 16.9896 10.9764ZM6.70856 18.7889H11.4787C12.0491 18.7889 12.3342 18.7889 12.5462 18.7537C13.9914 18.5134 14.9541 17.1289 14.676 15.6906C14.6353 15.4795 14.536 15.2123 14.3374 14.6776C14.1721 14.2326 14.0895 14.0101 13.9933 13.8159C13.3594 12.5347 12.1128 11.668 10.6911 11.5198C10.4756 11.4973 10.2383 11.4973 9.76351 11.4973H8.42378C7.94903 11.4973 7.71166 11.4973 7.49616 11.5198C6.07442 11.668 4.82793 12.5347 4.19392 13.8159C4.09782 14.0101 4.01517 14.2326 3.84987 14.6776C3.6513 15.2123 3.55201 15.4795 3.51122 15.6906C3.23324 17.1289 4.19592 18.5134 5.64104 18.7537C5.8531 18.7889 6.13826 18.7889 6.70856 18.7889Z"
                        stroke="#2C3422"
                        strokeWidth="1.5625"
                        strokeLinejoin="round"
                    />
                </svg>
            ),
        },
        {
            id: 3,
            header: 'Trust & Security First',
            text: 'We are committed to providing a secure platform with top-tier protection to ensure your funds and personal data are always safe.',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path
                        d="M10.4666 4.26308C11.6587 2.98758 13.6814 2.98758 14.8736 4.26308C15.4681 4.89925 16.3086 5.2474 17.1789 5.21798C18.9237 5.15902 20.354 6.5893 20.295 8.33416C20.2657 9.20443 20.6138 10.0449 21.2499 10.6395C22.5254 11.8316 22.5254 13.8543 21.2499 15.0464C20.6138 15.641 20.2657 16.4815 20.295 17.3518C20.354 19.0966 18.9237 20.5269 17.1789 20.468C16.3086 20.4386 15.4681 20.7867 14.8736 21.4229C13.6814 22.6984 11.6587 22.6984 10.4666 21.4229C9.87201 20.7867 9.03151 20.4386 8.16125 20.468C6.41639 20.5269 4.98611 19.0966 5.04508 17.3518C5.07448 16.4815 4.72634 15.641 4.09017 15.0464C2.81467 13.8543 2.81467 11.8316 4.09017 10.6395C4.72634 10.0449 5.07448 9.20443 5.04508 8.33416C4.98611 6.5893 6.41639 5.15902 8.16125 5.21798C9.03151 5.2474 9.87201 4.89925 10.4666 4.26308Z"
                        stroke="#141711"
                        strokeWidth="1.5625"
                    />
                    <path d="M16.8365 8.67755L8.50317 17.0109" stroke="#141711" strokeWidth="1.5625" strokeLinecap="round" />
                    <path
                        d="M11.1073 9.97963C11.1073 10.6988 10.5244 11.2817 9.80526 11.2817C9.08613 11.2817 8.50317 10.6988 8.50317 9.97963C8.50317 9.26051 9.08613 8.67755 9.80526 8.67755C10.5244 8.67755 11.1073 9.26051 11.1073 9.97963Z"
                        stroke="#141711"
                        strokeWidth="1.5625"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M16.8366 15.7088C16.8366 16.428 16.2537 17.0109 15.5345 17.0109C14.8153 17.0109 14.2324 16.428 14.2324 15.7088C14.2324 14.9897 14.8153 14.4067 15.5345 14.4067C16.2537 14.4067 16.8366 14.9897 16.8366 15.7088Z"
                        stroke="#141711"
                        strokeWidth="1.5625"
                        strokeLinejoin="round"
                    />
                </svg>
            ),
        },
    ];

    return (
        <>
            <div className="w-full flex flex-col px-[2.5rem]">
                <span className="landing_features_header">What we’re all about</span>

                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[3.75rem] mt-[2.53rem]">
                    {featureItems.map((item) => {
                        const { id, header, text, icon } = item;
                        return <AboutCard key={id} header={header} text={text} icon={icon} />;
                    })}
                </div>

                <div className="hidden w-full h-full xl:flex items-start justify-center gap-[1.88rem] mt-[2.75rem]">
                    <img src={aboutAfrica} className="w-full xl:w-[42.1875rem] h-[34.74875rem]" alt="africa" />
                    
                    <Link to="/registration">
                        <img src={aboutReady} className="w-full xl:w-[42.1875rem] h-[34.74875rem]" alt="get started" />
                    </Link>
                </div>
            </div>
        </>
    );
}
