import React, { useRef } from 'react';
import { Form, Input, Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, customToast } from '../../../utils';
import { save } from '../../../features/save/saveSlice';
import PhoneInput from 'react-phone-input-2';

export default function UpdateUserDetailsModal({ open, handleCancel, header, otpObj, handleUpdate }) {
    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const formRef = useRef(null);

    const { saving } = useSelector((state) => state.save);
    const { user } = useSelector((state) => state.auth);

    const onFinish = async (data) => {
        const saveObj = {
            usrId: user?.jti,
            otpType: otpObj?.otpType,
            ...data,
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/custom-otp',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await form.resetFields();
            await handleUpdate(data);
            return;
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'We cannot process your request at the moment',
                bdColor: 'error',
                id: 999990000,
            });
        }
    };

    async function handleClose() {
        await handleCancel();
        await form.resetFields();
    }

    return (
        <>
            <Modal centered className="update-user-details-modal" title={header} open={open} footer={false}>
                <div className="px-[2.94rem] pt-[5rem] w-full flex flex-col">
                    <span className="heading_3">{header}</span>
                    <div className="w-full flex flex-col gap-[.5rem] mt-[1.25rem]">
                        <Form
                            layout="vertical"
                            ref={formRef}
                            name="updateUserDetailsModal"
                            onFinish={onFinish}
                            style={{
                                maxWidth: '100%',
                                width: '100%',
                            }}
                            initialValues={{
                                memberIds: [],
                            }}
                            form={form}
                        >
                            {otpObj?.otpType === 'EMAIL' ? (
                                <Form.Item
                                    label="Enter your new email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Field is required',
                                        },
                                    ]}
                                >
                                    <Input type="email" className="input" />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    label="Phone number"
                                    name="phone"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Phone number is required',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (value && value.length >= 12 && value.length <= 13) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Phone number must be exactly 10 characters excluding country code'));
                                            },
                                        },
                                    ]}
                                >
                                    <PhoneInput enableSearch country="ke" countryCodeEditable={false} className="input" />
                                </Form.Item>
                            )}

                            <div className="flex items-center justify-end mt-[5rem] w-full">
                                <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
                                    <button disabled={saving} onClick={handleClose} className="cstm-btn" type="button">
                                        Cancel
                                    </button>

                                    <button disabled={saving} className="cstm-btn-2" type="submit">
                                        {saving ? <Spin /> : 'Next'}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
}
