import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spin } from 'antd';
import { save } from '../../features/save/saveSlice';
import { applicationUrl, customToast, getRandomColor, icon } from '../../utils';
import { fetchChamaGroupByCode } from '../../features/fetch/fetchSlice';

export default function JoinChamaGroup() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { loading, chamaGroupObj } = useSelector((state) => state.fetch);
	const { saving } = useSelector((state) => state.save);

	const inputRefs = [
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
	];

	const [success, setsuccess] = useState(false);

	const [verificationCode, setVerificationCode] = useState([
		'',
		'',
		'',
		'',
		'',
		'',
	]);

	const cancatenatedString = verificationCode?.join('');

	const handleInputChange = (e, index) => {
		const { value } = e.target;

		if (value === '') {
			if (index > 0) {
				inputRefs[index - 1].current.focus();
			}
		} else if (index < inputRefs.length - 1) {
			inputRefs[index + 1].current.focus();
		}

		const updatedVerificationCode = [...verificationCode];
		updatedVerificationCode[index] = value;
		setVerificationCode(updatedVerificationCode);
	};

	async function hanldeGetChama() {
		await dispatch(fetchChamaGroupByCode(cancatenatedString));
	}

	async function onFinish(data) {
		data.url = applicationUrl().url;
		data.saveUrl = `/api/v1/invite/requestJoin/${chamaGroupObj?.custId}`;

		const res = await dispatch(save(data));

		if (res?.payload?.success) {
			setsuccess(true);
		} else {
			customToast({
				content: res?.payload?.messages?.message ?? 'An error occured',
				bdColor: 'error',
			});
		}
	}
    
	useEffect(() => {
		if (cancatenatedString.length === 6) {
			hanldeGetChama();
		}
	}, [cancatenatedString]);

	return (
		<>
			<div className='onboarding'>
				<div className='w-full flex justify-center items-center gap-[.94rem] '>
					<div
						style={{
							borderRadius: '0.875rem',
							background: '#FFF',
							boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
						className='w-full lg:w-[41rem] border border-blkDark'>
						<div className='flex justify-center items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]'>
							<span className='heading_4 !text-white'>Join chama group</span>
						</div>

						{success ? (
							<>
								<div className='px-[2.94rem] py-[5rem]'>
									<div className='flex flex-col'>
										<div
											style={{
												borderRadius: '0.5rem',
												border: '1px solid #3BBB6E',
												background: '#FFF',
												boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
											}}
											className='w-full flex items-start gap-[.5rem] p-[1rem]'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'>
												<path
													d='M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986'
													stroke='#3BBB6E'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
												<path
													d='M22 4L12 14.01L9 11.01'
													stroke='#3BBB6E'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</svg>

											<div className='flex flex-col gap-[.5rem]'>
												<span className='modal_success_header'>Success</span>
												<span className='modal_success_text'>
													Your requst was succesful. The chama admin will be
													notified of your request. Please wait for approval.
												</span>
											</div>
										</div>
									</div>
									<div className='flex items-center justify-center mt-[5rem] w-full'>
										<div className='w-[12.4375rem] flex items-center gap-[.5rem]'>
											<button
												onClick={() => navigate('/dashboard')}
												className='cstm-btn-2'
												type='button'>
												Okay
											</button>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<div className='px-[.75rem] lg:px-[2.94rem] py-[5rem]'>
									<div className='w-full flex flex-col  items-center'>
										{icon}
										<span className='heading_5 text-center lg:text-left mt-[1.25rem]'>
											Enter the 6 digit chama group code to join
										</span>
										<div className='flex w-full flex-col mt-[2.5rem]'>
											<div className='flex w-full items-center justify-center gap-[.75rem] lg:gap-[1.25rem]'>
												{inputRefs?.map((ref, index) => (
													<div key={index}>
														<input
															ref={ref}
															className='otp-input'
															type='text'
															maxLength='1'
															value={verificationCode[index]}
															onChange={(e) => handleInputChange(e, index)}
														/>
													</div>
												))}
											</div>
										</div>

										<Form
											layout='vertical'
											ref={formRef}
											name='newUserInvite'
											onFinish={onFinish}
											style={{
												maxWidth: '100%',
												marginTop: '3.75rem',
												width: '100%',
											}}
											form={form}>
											{chamaGroupObj?.custId ? (
												<>
													<div
														style={{
															border: '1px solid var(--Tile-stroke, #E2E9EE)',
														}}
														className='w-full flex items-center h-full px-[.625rem] py-[.75rem] lg:py-[2.06rem] rounded-[1.5rem] min-h-[14rem] gap-[1.5rem]'>
														<div className='flex items-start flex-shrink-0'>
															<img
																src={`https://ui-avatars.com/api/?name=${encodeURIComponent(chamaGroupObj?.custName)}&background=${getRandomColor()}&color=fff`}
																className='w-[5.25rem] h-[5.25rem] rounded-full object-cover'
																alt='Chama icon'
															/>
														</div>

														<div className='flex flex-col w-full'>
															<span className='heading_4'>
																{chamaGroupObj?.custName} |{' '}
																{chamaGroupObj?.custMemberCount} members
															</span>
															<span className='chama_card_date mt-[.75rem]'>
																Created {' 5th March 2024'}
															</span>
														</div>
													</div>
												</>
											) : null}
											<div className='flex items-center justify-between mt-[5rem] w-full'>
												<div className='w-[8.25rem] flex'>
													<button
														onClick={() => navigate(-1)}
														disabled={loading}
														className='cstm-btn'
														type='button'>
														Cancel
													</button>
												</div>

												{chamaGroupObj?.custId && !loading && cancatenatedString.length === 6 ? (
													<div className='w-[12.4375rem] flex items-center gap-[.5rem]'>
														<button
															disabled={loading || saving }
															className='cstm-btn-2'
															type='submit'>
															{loading || saving ? <Spin /> : 'Join'}
														</button>
													</div>
												) : null}
											</div>
										</Form>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
