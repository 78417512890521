import { useSelector } from 'react-redux';
import BannerComponent from './landing_page_components/BannerComponent';
import FeaturesComponent from './landing_page_components/FeaturesComponent';
import NavbarComponent from './landing_page_components/NavbarComponent';
import { Navigate } from 'react-router-dom';
import GetStartedComponent from './landing_page_components/GetStartedComponent';
import InsightsComponent from './landing_page_components/InsightsComponent';
import StressFreeComponent from './landing_page_components/StressFreeComponent';
import AboutComponent from './landing_page_components/AboutComponent';
import ReachOutComponent from './landing_page_components/ReachOutComponent';
import FAQsComponent from './landing_page_components/FAQsComponent';
import FooterComponent from './landing_page_components/FooterComponent';
import { useRef } from 'react';

export default function LandingPage() {
    const { isActive } = useSelector((state) => state.auth);

    const home = useRef();
    const feature = useRef();
    const about = useRef();
    const help = useRef();

    function handleNavigate(section) {
        switch (section) {
            case 'home':
                home.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                break;
            case 'features':
                feature.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                break;
            case 'about':
                about.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                break;

            case 'help':
                help.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                break;
            default:
                console.warn('Unknown section:', section);
        }
    }

    if (isActive) {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <>
            <div className="bg-[#F9F4F1] w-full h-full pb-[6.88rem]">
                <div className="pt-[1.88rem] px-[2.5rem] w-full">
                    <NavbarComponent handleNavigate={handleNavigate} />
                </div>

                <div ref={home} className="mt-[5rem] w-full">
                    <BannerComponent />
                </div>
            </div>

            <div ref={feature} className="pt-[7.26rem] w-full !bg-white">
                <FeaturesComponent />

                <div className="mt-[4.69rem] px-0 xl:px-[1.88rem]">
                    <GetStartedComponent />
                </div>

                <div className="mt-[7.25rem] px-0 xl:px-[1.88rem]">
                    <InsightsComponent />
                </div>

                <div className="mt-[7.25rem]">
                    <StressFreeComponent />
                </div>

                <div ref={about} className="mt-[7.25rem]">
                    <AboutComponent />
                </div>

                <div ref={help} className="mt-[7.25rem] px-0 xl:px-[1.88rem]">
                    <ReachOutComponent />
                </div>

                <div className="mt-[7.25rem] mb-[8.13rem] px-[.75rem] xl:px-[14.38rem]">
                    <FAQsComponent />
                </div>

                <div className="px-[3.75rem] pb-[3.75rem] pt-[6.88rem] bg-[#12110F]">
                    <FooterComponent handleNavigate={handleNavigate} />
                </div>
            </div>
        </>
    );
}
