import { Table } from 'antd';
import moment from 'moment';
import { applicationUrl, capitalize, formatMoney } from '../../../utils';
import { useSelector } from 'react-redux';
import useFetch from '../../../custom_hooks/useFetch';
import TableLoading from '../../../components/TableLoading';
import { debitNoteTypeColor } from '../../../data';

const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        sorter: (a, b) => a - b,
    },
    {
        title: 'Activity',
        dataIndex: 'trxSource',
        render: (item) => {
            const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6';

            return (
                <div
                    style={{
                        background: roleColor,
                    }}
                    className="flex w-auto min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                        <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                    </svg>
                    <span>{capitalize(item?.split('_').join(' '))}</span>
                </div>
            );
        },
    },
    {
        title: 'Debit (KES)',
        render: (item) => {
            const { trxType, trxAmount } = item;
            if (trxType === 'DEBIT') {
                return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
            }
            return <span>-</span>;
        },
    },
    {
        title: 'Credit (KES)',
        render: (item) => {
            const { trxType, trxAmount } = item;
            if (trxType === 'CREDIT') {
                return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
            }
            return <span>-</span>;
        },
    },
    // {
    //     title: 'Running balance (KES)',
    //     dataIndex: 'trxRef',
    //     render: (item) => <span>-</span>,
    // },
];

export default function MemberRecentTransactionTable() {
    const { activeMember } = useSelector((state) => state.obj);

    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/transactions/recent?memberNo=${activeMember?.gpmNumber}`);

    if (isLoading) return <TableLoading />;

    return (
        <div className="w-full flex">
            <Table
                rowKey="trxId"
                className="mt-[1.5rem] w-full"
                pagination={false}
                columns={columns}
                dataSource={data?.content?.slice(0, 5)}
            />
        </div>
    );
}
