import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, customToast } from '../utils';
import { save } from '../features/save/saveSlice';

export default function CustomAllPurposeOtpModal({ open, handleCancel, handleNext, text, otpObj }) {
    const dispatch = useDispatch();

    const { saving } = useSelector((state) => state.save);
    const { user } = useSelector((state) => state.auth);

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
    const [counter, setCounter] = useState({ minutes: 0, seconds: 0 });

    const cancatenatedString = verificationCode?.join('');

    const handleInputChange = (e, index) => {
        const { value } = e.target;

        if (value === '') {
            if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        } else if (index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }

        const updatedVerificationCode = [...verificationCode];
        updatedVerificationCode[index] = value;
        setVerificationCode(updatedVerificationCode);
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    const onFinish = async () => {
        await setVerificationCode(['', '', '', '']);
        await handleNext(cancatenatedString);
    };

    async function handleResendOtp() {
        const saveObj = {
            usrId: user?.jti,
            otpType: otpObj?.otpType,
            phone: otpObj?.phone,
            email: otpObj?.email,
            url: applicationUrl().url,
            saveUrl: '/api/v2/users/custom-otp',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            setCounter({ minutes: 0, seconds: 5 });
            await customToast({
                content: res?.payload?.messages?.message,
                bdColor: 'success',
                id: 729,
            });
        } else {
            await customToast({
                content: res?.payload?.messages?.message ?? 'We cannot process your request at the momemnt. Please try again later',
                bdColor: 'error',
                id: 8383,
            });
        }
    }

    async function handleClose() {
        await handleCancel();
        await setVerificationCode(['', '', '', '']);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (counter.minutes === 0 && counter.seconds === 0) {
                clearInterval(interval);
            } else {
                setCounter((prevCounter) => {
                    if (prevCounter.seconds === 0) {
                        return { minutes: prevCounter.minutes - 1, seconds: 5 };
                    } else {
                        return { ...prevCounter, seconds: prevCounter.seconds - 1 };
                    }
                });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [counter]);

    useEffect(() => {}, [otpObj, text]);

    return (
        <>
            <Modal
                afterOpenChange={(isOpen) => (isOpen ? setCounter({ minutes: 0, seconds: 5 }) : setCounter({ minutes: 0, seconds: 0 }))}
                centered
                className="custom-all-purpose-otp-modal"
                title="Verification"
                open={open}
                footer={false}
            >
                <div className="px-[2.94rem] pt-[5rem] w-full flex flex-col">
                    <span className="heading_3">Verification</span>
                    <div className="w-full flex flex-col gap-[.5rem] mt-[1.25rem]">
                        <span className="label_2">{text ?? 'Enter the 4 digit code sent to your email or phone'}</span>

                        <div className="flex flex-col">
                            <div className="flex items-center gap-[1.25rem]">
                                {inputRefs?.map((ref, index) => (
                                    <div key={index}>
                                        <input
                                            ref={ref}
                                            className="otp-input"
                                            type="text"
                                            maxLength="1"
                                            value={verificationCode[index]}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex w-full">
                            {counter.minutes === 0 && counter.seconds === 0 ? (
                                <div className="flex items-center gap-[.2rem] label_2">
                                    <span className="text-[#4B5563]">Didn’t receive OTP?</span>
                                    <button disabled={saving} className="text-[#3482FF]" onClick={handleResendOtp} type="button">
                                        Resend
                                    </button>
                                </div>
                            ) : (
                                <div className="flex items-center gap-[.2rem] label_2">
                                    <span className="text-[#4B5563]">Resend in</span>
                                    <span className="text-[#3482FF]">
                                        {counter.minutes.toString().padStart(2, '0')}:{counter.seconds.toString().padStart(2, '0')}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="flex items-center justify-end mt-[5rem] w-full">
                            <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
                                <button disabled={saving} onClick={handleClose} className="cstm-btn" type="button">
                                    Cancel
                                </button>

                                <button disabled={saving || cancatenatedString.length !== 4} onClick={onFinish} className="cstm-btn-2" type="button">
                                    {saving ? <Spin /> : 'Next'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
