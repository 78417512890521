import { useDispatch, useSelector } from 'react-redux';
import { Form, Steps } from 'antd';
import { useRef, useState } from 'react';
import { customToast } from '../../utils';
import { handleAuthData, logout, register } from '../../features/auth/authSlice';
import PersonalDetailsStep from './registration_steps/PersonalDetailsStep';
import PasswordStep from './registration_steps/PasswordStep';
import VerifyIdStep from './registration_steps/VerifyIdStep';
import LocationStep from './registration_steps/LocationStep';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import image10 from '../../assets/svg/ndovuLogoNew.svg';


export default function Register() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authData, isActive } = useSelector((state) => state.auth);

    const formRef = useRef(null);

    const [current, setcurrent] = useState(0);

    const onFinish = async (data) => {
        data.usrId = authData?.usrId;

        const res = await dispatch(register(data));

        if (res?.payload?.success) {
            await customToast({
                content: res?.payload?.messages?.message,
                bdColor: 'success',
                id: 9989877626626,
            });
            await dispatch(logout());
            await navigate('/login');
        } else {
            await customToast({
                content: res?.payload?.message,
                bdColor: 'error',
                id: 72676378292,
            });
        }
    };

    function handleNext() {
        setcurrent(current + 1);
    }

    function handleBack() {
        setcurrent(current - 1);
    }

    const steps = [
        {
            key: 0,
            title: 'Personal Details',
            content: <PersonalDetailsStep handleNext={handleNext} form={form} />,
        },
        {
            key: 1,
            title: 'Verify ID',
            content: <VerifyIdStep handleNext={handleNext} handleBack={handleBack} form={form} />,
        },
        {
            key: 2,
            title: 'Location',
            content: <LocationStep handleNext={handleNext} handleBack={handleBack} form={form} />,
        },
        {
            key: 3,
            title: 'Password Setup',
            content: <PasswordStep handleBack={handleBack} form={form} />,
        },
    ];

    const items = steps.map((item) => ({
        key: item.key,
        title: item.title,
        icon: item.icon,
    }));

    useEffect(() => {
        form.setFieldsValue({
            ...authData,
            usrDob: authData?.usrDob ? dayjs(authData?.usrDob) : null,
        });
    }, [authData, form]);

    if (isActive) {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <>
            <div className="flex w-full justify-center pt-[3.69rem] register">
                <div className="w-full lg:w-[50rem] xl:w-[60rem] flex flex-col h-auto">
                    <Link to="/" className="flex items-center w-full gap-[.5rem] px-[2.31rem] lg:p-0">
                        <img src={image10} alt="logo" />
                        <span className="heading_4">Chamify Africa.</span>
                    </Link>

                    <span className="heading_1 mt-[1.75rem] px-[2.31rem] lg:p-0">Sign up</span>
                    <span className="paragraph_1 mt-[.75rem] px-[2.31rem] lg:p-0">Let’s create an account.</span>

                    <div className="w-full h-full mt-0 lg:mt-[2.44rem]">
                        <Form
                            onValuesChange={(data) => {
                                dispatch(handleAuthData(data));
                            }}
                            layout="vertical"
                            ref={formRef}
                            name="registration"
                            onFinish={onFinish}
                            scrollToFirstError
                            form={form}
                            initialValues={{
                                ...authData,
                                usrDob: authData?.usrDob ? dayjs(authData?.usrDob) : null,
                            }}
                            className=""
                        >
                            <div className="hidden lg:flex">
                                <Steps className="register_step" current={current} labelPlacement="vertical" items={items} />
                            </div>

                            <div className="mt-[2.12rem] p-[2.31rem]">{steps[current].content}</div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}
