import LandingPageImageComponent from "./LandingPageImageComponent";

export default function BannerComponent() {
    return (
        <>
            <div className="w-full flex justify-center items-center flex-col gap-[1.56rem]">
                <span className="landing_banner_header">Take your chama <span className="!text-[#DF5F2C]">savings</span> to <br/> the next level.</span>
                <span className="landing_banner_sub_header p-[.5rem] xl:p-0"><span className="!text-[#E58D27]">Join the culture.</span> Save together, grow together. <br className="hidden xl:flex" />
                Achieve financial freedom with the support of community.</span>
            </div>

            <div className="mt-[3.25rem] px-[1.88rem]">
                <LandingPageImageComponent />
            </div>
        </>
    );
}
